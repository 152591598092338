import { ColorTransparent } from 'components/shared/ColorTransparent';
import React from 'react';
import { isActiveColor } from '../PaletteColorSettings/constants';
import { ActiveColorInterface } from '../PaletteColorSettings/types';
import { ShapeType } from 'components/shared/ColorTransparent/types';
import { NoopValueType } from 'types/global';

interface SelectableColorTransparentProps {
  colorId: string;
  groupId: string;
  colorIndex: number;
  hex: string;
  colorSize: number;
  value: ActiveColorInterface;
  onChange: React.Dispatch<React.SetStateAction<ActiveColorInterface>>;
  defaultActiveColor: ActiveColorInterface;
  shape?: ShapeType;
  disabledDeleteIcon?: boolean;
  onDelete?: NoopValueType<string>;
  name?: string;
  isAllActiveColor?: boolean;
}

export const SelectableColorTransparent: React.FC<SelectableColorTransparentProps> = ({
  colorId,
  groupId,
  colorIndex,
  hex,
  colorSize,
  value,
  name,
  onChange,
  defaultActiveColor,
  shape,
  disabledDeleteIcon,
  onDelete,
  isAllActiveColor,
}) => {
  const newActiveColorIds = { colorId, groupId };

  const onColorClick = () => {
    onChange(({ activeColorIds }) =>
      isActiveColor(newActiveColorIds, activeColorIds) ? defaultActiveColor : { activeColorIds: newActiveColorIds, colorIndex },
    );
  };

  const isActive = isActiveColor(newActiveColorIds, value?.activeColorIds);

  return (
    <ColorTransparent
      name={name}
      shape={shape}
      active={isActive || isAllActiveColor}
      onClick={onColorClick}
      key={colorId}
      color={hex}
      colorSize={colorSize}
      disabledDeleteIcon={disabledDeleteIcon}
      onDelete={() => onDelete && onDelete(colorId)}
    />
  );
};

import { ElementContainerSettings, GroupContainerSettings, MainContainerSettings } from 'modules/settingsContainer';
import Select from 'modules/ui/Select';
import React from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { useSelector } from 'react-redux';
import { getActivePalette, getPalettesAsArray } from 'store/reducers/palettes/getters';
import { PaletteColorSettings } from 'modules/workspace/components/panelSettingsApp/tabsContent/PalettesTab/PaletteColorSettings';
import { PaletteControlSettings } from 'modules/workspace/components/panelSettingsApp/tabsContent/PalettesTab/PaletteControlSettings';
import { useAppDispatch } from 'store';
import { updatePaletteLinkAction } from 'store/reducers/themes/actions';
import { getActiveThemeId } from 'store/reducers/themes/getters';
import {
  AddColorType,
  AddGroupType,
  ChangeColorsType,
  ChangeColorType,
  ChangeGroupNameType,
  CopyGroupType,
  DeleteColorType,
  DeleteGroupType,
} from 'modules/workspace/components/panelSettingsApp/tabsContent/PalettesTab/PaletteColorSettings/types';
import {
  addColorToGroupByCurrentPaletteAction,
  addGroupToCurrentPaletteAction,
  addNewPaletteAction,
  addNewPaletteByCurrentPaletteAction,
  removeColorFromGroupByCurrentPaletteAction,
  removeGroupFromPaletteByIdAction,
  removePaletteByIdAction,
  updatePaletteAction,
} from 'store/reducers/palettes/actions';
import { getPaletteColorItem, getPaletteItem } from 'store/reducers/palettes/constants';
import { TxtIcon } from 'assets/icons/editor';
import { getHls } from 'utils/utils';
import { TooltipIconButton } from 'modules/ui/TooltipIconButton';

export const PalettesTab = () => {
  const dispatch = useAppDispatch();

  const palettes = useSelector(getPalettesAsArray);
  const activePalette = useSelector(getActivePalette);
  const activeThemeId = useSelector(getActiveThemeId);

  if (!activePalette || !activeThemeId)
    return (
      <MainContainerSettings>
        <FlexContainer height="40px" />
      </MainContainerSettings>
    );

  const onPaletteChange = (paletteId: string) => dispatch(updatePaletteLinkAction({ themeId: activeThemeId, paletteId }));

  const onDeleteColor: DeleteColorType = ({ colorId, groupId }) =>
    dispatch(removeColorFromGroupByCurrentPaletteAction({ groupId, colorId }));

  const onDeleteGroup: DeleteGroupType = ({ groupId }) =>
    dispatch(removeGroupFromPaletteByIdAction({ id: activePalette.id, groupId }));

  const onAddColor: AddColorType = async ({ color, groupId }) => {
    const resultAction = await dispatch(
      addColorToGroupByCurrentPaletteAction({ groupId, color: getPaletteColorItem({ ...color }) }),
    );

    if (addColorToGroupByCurrentPaletteAction.fulfilled.match(resultAction)) {
      const payload = resultAction.payload as { groupId: string; colorId: string };

      return payload;
    }
  };

  const onAddGroup: AddGroupType = ({ color }) =>
    dispatch(addGroupToCurrentPaletteAction({ group: { colors: [getPaletteColorItem({ ...color })] } }));

  const onCopyGroup: CopyGroupType = (props) => dispatch(addGroupToCurrentPaletteAction(props));

  const onAddPalette = () => dispatch(addNewPaletteAction(getPaletteItem({ name: 'Новая палитра' })));

  const onCopyPalette = () => dispatch(addNewPaletteByCurrentPaletteAction());

  const onPaletteNameChange = (name: string) => dispatch(updatePaletteAction({ ...activePalette, name }));

  const onDeletePalette = () => dispatch(removePaletteByIdAction(activePalette.id));

  const onColorChange: ChangeColorType = ({ groupId, colorId, color }) =>
    dispatch(
      updatePaletteAction({
        ...activePalette,
        groups: activePalette.groups.map((group) =>
          group.id === groupId
            ? {
                ...group,
                colors: group.colors.map((oldColor) => (oldColor.id === colorId ? { ...oldColor, ...color } : oldColor)),
              }
            : group,
        ),
      }),
    );

  const onColorsChange: ChangeColorsType = ({ groupId, color }) =>
    color &&
    dispatch(
      updatePaletteAction({
        ...activePalette,
        groups: activePalette.groups.map((group) =>
          group.id === groupId
            ? {
                ...group,
                colors: group.colors.map((oldColor) => ({
                  ...oldColor,
                  ...color,
                  hex: getHls({ ...oldColor, ...color }),
                })),
              }
            : group,
        ),
      }),
    );

  const onGroupNameChange: ChangeGroupNameType = ({ groupId, name }) =>
    dispatch(
      updatePaletteAction({
        ...activePalette,
        groups: activePalette.groups.map((group) =>
          group.id === groupId
            ? {
                ...group,
                name,
              }
            : group,
        ),
      }),
    );

  const onToggleShowGroupNames = () => {
    dispatch(
      updatePaletteAction({
        ...activePalette,
        showGroupNames: !activePalette.showGroupNames,
      }),
    );
  };

  return (
    <>
      <MainContainerSettings>
        <GroupContainerSettings>
          <ElementContainerSettings>
            <FlexContainer width="100%" justifyContent="space-between">
              <Select
                needBackground={false}
                placeholder="Выбрать палитру"
                name="palette_name"
                options={palettes.map(({ id, name }) => ({
                  value: id,
                  name,
                }))}
                width="60%"
                value={activePalette.id}
                onChange={onPaletteChange}
              />
              <TooltipIconButton
                title="Названия групп"
                selected={activePalette.showGroupNames}
                leftIcon={<TxtIcon />}
                needBackground={false}
                onClick={onToggleShowGroupNames}
              />
            </FlexContainer>
          </ElementContainerSettings>
        </GroupContainerSettings>
        <PaletteColorSettings
          palette={activePalette}
          onDeleteColor={onDeleteColor}
          onDeleteGroup={onDeleteGroup}
          onAddColor={onAddColor}
          onAddGroup={onAddGroup}
          onCopyGroup={onCopyGroup}
          onColorChange={onColorChange}
          onColorsChange={onColorsChange}
          onGroupNameChange={onGroupNameChange}
        />
      </MainContainerSettings>
      <PaletteControlSettings
        onPaletteNameChange={onPaletteNameChange}
        onAddPalette={onAddPalette}
        onCopyPalette={onCopyPalette}
        palette={activePalette}
        onDeletePalette={onDeletePalette}
        disableDeletePalette={palettes.length === 1}
      />
    </>
  );
};

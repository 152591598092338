import { ROLES_ENUM } from 'enums/RolesEnum';
import { ProjectAvatarInterface } from '../projectManager/types';

export enum ActionsTypes {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  LOGOUT_FROM_ALL_MY_SESSION = 'LOGOUT_FROM_ALL_MY_SESSION',
  GET_ROLES_LIST_ENUM = 'GET_ROLES_LIST_ENUM',
  GET_USER = 'GET_USER',
  GET_HAS_ACCESS_TO_PROJECT = 'GET_HAS_ACCESS_TO_PROJECT',
}

export interface AuthState extends IResponseStatus {
  entity: string | null;
}

export const defaultTokenState = {
  entity: null,
  loading: false,
  loaded: false,
  error: null,
};

export const defaultRolesListEntity: IRolesList = {
  kind: null,
  roles: [],
};

export const defaultUserEntity: IAuthenticatedUser = {
  login: null,
  role: null,
  createdAt: null,
  updatedAt: null,
  license: { status: false },
};

export const initialState: IAuthState = {
  token: defaultTokenState,
  rolesList: { entity: defaultRolesListEntity, loading: false, loaded: false, error: null, timestampState: null },
  user: { entity: defaultUserEntity, loading: false, loaded: false, error: null, timestampState: null },
};

export interface IAuthState {
  token: AuthState;
  rolesList: IRolesListState;
  user: IUserState;
}

export interface IRolesListState extends IResponseStatus, IResponseTimeStamp {
  entity: IRolesList;
}

export interface IUserState extends IResponseStatus, IResponseTimeStamp {
  entity: IAuthenticatedUser;
}

export interface IAuthenticatedUser {
  login: string | null;
  role: ROLES_ENUM | null;
  createdAt: string | null;
  updatedAt: string | null;
  license?: { status: boolean };
  email?: string | null;
  name?: string | null;
  surname?: string | null;
  avatar?: ProjectAvatarInterface;
}

export interface IRolesList {
  kind: string | null;
  roles: IRoleElem[];
}

export interface IRoleElem {
  id: string;
  title: string;
}

export interface IResponseStatus {
  loading: boolean;
  loaded: boolean;
  error: string | null | undefined;
}

export interface IResponseTimeStamp {
  timestampState: number | null;
}

import { ContainerSettingsInterface } from 'types/store';
import {
  ColorByConditionInterface,
  ColorByInterface,
  ColorByRuleInterface,
  ColorByValueInterface,
  ColorByValueSpecificInterface,
} from 'store/reducers/visualisations/types';
import { ContainerValuesEnum, FlexDirectionEnum, GroupSettingsStylesInterface } from 'store/reducers/groupsVisualisations/types';
import { GroupContainerStylesEnum } from 'modules/settingsContainer/common/view/WidgetGroupSettingsContainer/types';

export const setSliceFn = <Slice extends object>(state: Slice, { payload: slice }: { payload: Slice }) => {
  (Object.keys(slice) as Array<keyof Slice>).forEach((key) => {
    (state[key] as Slice[keyof Slice]) = slice[key];
  });
};

export const getDefaultByRuleSetting = (alias: string) => {
  return {
    alias,
    defaultColor: null,
    sqlCondition: null,
    rules: [],
  };
};

export const defaultColorByCondition: ColorByConditionInterface = {
  colors: [],
  sqlCondition: null,
  alias: 'shadowColor',
};

export const defaultColorByValue: ColorByValueInterface = {
  alias: 'shadowColor',
  colors: null,
};

export const defaultColorByValueSpecific: ColorByValueSpecificInterface = {
  alias: 'shadowColor',
  colors: { colorSpecificAlias: [{ value: null }, { value: null }] },
  values: [0],
};

export const defaultColorRuleCondition: ColorByRuleInterface = {
  id: null,
  rule: null,
  firstValue: null,
  secondValue: null,
  elementColor: null,
  fieldName: null,
  customRequest: null,
  operationType: null,
  isActive: true,
};

export const defaultBackgroundColorBy: ColorByInterface = {
  type: 'default',
  isActive: false,
  byCondition: {
    colors: [],
    sqlCondition: null,
    alias: 'backgroundColor',
  },
  byValue: {
    alias: 'backgroundColor',
    colors: null,
  },
  byValueSpecific: {
    alias: 'backgroundColor',
    colors: { colorSpecificAlias: [{ value: null }, { value: null }] },
    values: [0],
  },
  byRule: getDefaultByRuleSetting('backgroundColor'),
};

export const defaultGroupContainerOptionValue = { name: 'Фиксированная', value: ContainerValuesEnum.FIXED };

export const defaultGroupStylesViewSettings: GroupSettingsStylesInterface = {
  align: GroupContainerStylesEnum.ALIGN_TOP,
  justify: GroupContainerStylesEnum.JUSTIFY_START,
  direction: FlexDirectionEnum.ROW,
  wrap: false,
};

export const defaultBackgroundBySettings: ColorByInterface = {
  type: 'default',
  isActive: false,
  byCondition: {
    colors: [],
    sqlCondition: null,
    alias: 'backgroundColor',
  },
  byValue: {
    alias: 'backgroundColor',
    colors: null,
  },
  byValueSpecific: {
    alias: 'backgroundColor',
    colors: { colorSpecificAlias: [{ value: null }, { value: null }] },
    values: [0],
  },
  byRule: getDefaultByRuleSetting('backgroundColor'),
};

export const defaultContainerSettings: ContainerSettingsInterface = {
  disableDragging: false,
  freeLocation: true,
  paddingSettings: {
    unsetPaddings: true,
    top: 16,
    left: 16,
    right: 16,
    bottom: 16,
  },
  fontFamilySettings: {
    isActive: false,
    fontFamily: '',
    fontStyle: '',
    fontUrl: '',
    fontWeight: '',
    variants: null,
  },
  widthOption: defaultGroupContainerOptionValue,
  heightOption: defaultGroupContainerOptionValue,
};

import React, { useMemo } from 'react';
import { Tab } from '../types';
import { Option } from 'components/shared/ui/menu/MenuIconList/types';
import { SettingsTabs } from '../SettingsTabs';
import { updateAllMainContainersOpen } from 'store/reducers/blockVisibilitySettings';
import { useSelector } from 'react-redux';
import { getAllMainContainersIsOpen } from 'store/reducers/blockVisibilitySettings/getters';
import { useAppDispatch } from 'store';

interface SettingsTabsWrapperProps {
  tabs: Tab[];
  disabledPointsMenu?: boolean;
}

export const SettingsTabsWrapper = ({ tabs, disabledPointsMenu = false }: SettingsTabsWrapperProps) => {
  const dispatch = useAppDispatch();

  const isOpenMainContainers = useSelector(getAllMainContainersIsOpen);

  const optionsPointsMenu: Option[] = useMemo(
    () => [
      {
        name: 'Раскрыть все секции',
        value: 'open',
        onClick: () => dispatch(updateAllMainContainersOpen(true)),
      },
      {
        name: 'Свернуть все секции',
        value: 'close',
        onClick: () => dispatch(updateAllMainContainersOpen(false)),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOpenMainContainers],
  );

  return <SettingsTabs tabs={tabs} optionsPointsMenu={!disabledPointsMenu ? optionsPointsMenu : undefined} />;
};

import { AxiosResponse } from 'axios';
import { axiosClient } from 'services/ApiClient';
import {
  CreateUserFlowsPayload,
  CreateUserGroupAccessPayload,
  CreateUserSourcesPayload,
  DeleteUserFlowPayload,
  DeleteUserGroupsPayload,
  DeleteUserSourcePayload,
  LoadUserFlowsPayload,
  LoadUserGroupsPayload,
  LoadUserSourcesPayload,
  UpdateUserFlowsPayload,
  UpdateUserSourcePayload,
  UserDeleteDraftProjectsPayload,
  UserLoadDraftProjectsPayload,
  UserPayload,
  UserUpdatePayload,
} from 'store/reducers/adminUsers/types';
import {
  CopyProjectVersionAnotherPayload,
  CreateProjectVersionAnotherPayload,
  MoveProjectPayload,
  ProtectProjectPayload,
  RenameProjectPayload,
  RestoreHistoryProjectPayload,
} from 'store/reducers/projectManager/types';

export const loadUsers = (): Promise<AxiosResponse<FastBoard.API.ApiAdminUserShortListResponseDTO>> =>
  axiosClient.get(`/api/v2/admin/users`);

export const loadUserInfo = (userId: string): Promise<AxiosResponse<FastBoard.API.ApiAdminUserResponseDTO>> =>
  axiosClient.get(`/api/v2/admin/user/${userId}`);

export const deleteUser = (userId: string): Promise<AxiosResponse<FastBoard.API.ApiAdminUserResponseDTO>> =>
  axiosClient.delete(`/api/v2/admin/user/${userId}`);

export const createUser = ({ login, isLicense, role, password, name, surname, email }: UserPayload) =>
  axiosClient.post<string, AxiosResponse<FastBoard.API.ApiAdminUserResponseDTO>, FastBoard.API.ApiAdminUserCreateDTO>(
    `/api/v2/admin/user`,
    {
      login,
      password,
      role,
      addToLicense: isLicense,
      ...(name && { name }),
      ...(surname && { surname }),
      ...(email && { email }),
    },
  );

export const updateUser = ({ userId, isLicense, role, password, name, surname, email }: UserUpdatePayload & { userId: string }) =>
  axiosClient.put<string, AxiosResponse<FastBoard.API.ApiAdminUserResponseDTO>, FastBoard.API.ApiAdminUserUpdateDTO>(
    `/api/v2/admin/user/${userId}`,
    {
      password,
      role,
      license: isLicense,
      ...(name && { name }),
      ...(surname && { surname }),
      ...(email && { email }),
    },
  );

/* Draft Projects */

export const loadDraftProjects = ({
  userId,
}: UserLoadDraftProjectsPayload): Promise<AxiosResponse<FastBoard.API.ApiAdminProjectListResponseDTO>> =>
  axiosClient.get(`/api/v2/admin/projects/for-user/${userId}`);

export const loadDraftsOfAllUsers = (): Promise<AxiosResponse<FastBoard.API.ApiAdminFlowsListResponseDTO>> =>
  axiosClient.get(`/api/v2/admin/flows/users/draft`);

export const loadShortDraftUserProject = (
  flowId: string,
): Promise<AxiosResponse<FastBoard.API.ApiAdminProjectShortListResponseDTO>> =>
  axiosClient.get(`/api/v2/admin/projects/for-flow/${flowId}/short`);

export const deleteUserDraftProject = ({ projectId }: UserDeleteDraftProjectsPayload) =>
  axiosClient.delete<string, AxiosResponse<string>, string>(`/api/v2/admin/project/${projectId}`);

export const loadUserDraftProjectInfo = ({
  projectId,
}: UserDeleteDraftProjectsPayload): Promise<AxiosResponse<FastBoard.API.ApiAdminProjectMetaResponseDTO>> =>
  axiosClient.get(`/api/v2/admin/project/${projectId}/meta`);

export const loadUserDraftProjectHistoryVersion = ({
  projectId,
}: UserDeleteDraftProjectsPayload): Promise<AxiosResponse<FastBoard.API.ApiAdminProjectVersionHistoryResponseDTO>> =>
  axiosClient.get(`/api/v2/admin/project/${projectId}/history/50/0`);

export const restoreHistoryUserDraftProject = ({ projectId, dateTimeHistory }: RestoreHistoryProjectPayload) =>
  axiosClient.post<
    string,
    AxiosResponse<FastBoard.API.ApiAdminIncomingsResponseDTO>,
    FastBoard.API.ApiAdminProjectVersionRollbackDTO
  >(`/api/v1/project/${projectId}/rollback`, {
    ts: dateTimeHistory,
  });

export const createUserDraftProjectVersionAnother = ({
  projectId,
  dateTimeHistory,
  name,
  flowId,
  runImport,
}: CreateProjectVersionAnotherPayload) =>
  axiosClient.post<
    string,
    AxiosResponse<FastBoard.API.ApiAdminProjectVersionCreateFromResponseDTO>,
    FastBoard.API.ApiAdminProjectVersionCreateFromDTO
  >(`/api/v2/admin/ops/project/create`, {
    from: { ts: dateTimeHistory, projectId },
    to: { name, flowId },
    runImport,
  });

export const copyUserDraftProjectVersionAnother = ({ projectToId, projectFromId, runImport }: CopyProjectVersionAnotherPayload) =>
  axiosClient.post<
    string,
    AxiosResponse<FastBoard.API.ApiAdminIncomingsResponseDTO>,
    FastBoard.API.ApiAdminProjectVersionCopyDTO
  >(`/api/v2/admin/ops/project/copy`, {
    from: { projectId: projectFromId },
    to: { projectId: projectToId },
    runImport,
  });

export const protectUserDraftProject = ({
  projectId,
  isProtected,
}: ProtectProjectPayload): Promise<AxiosResponse<FastBoard.API.ApiAdminProjectUpdateProtectResponseDTO>> =>
  axiosClient.put(`/api/v2/admin/project/${projectId}/protect`, { isProtected });

export const renameDraftUserProject = ({
  id,
  name,
}: RenameProjectPayload): Promise<AxiosResponse<FastBoard.API.ApiAdminProjectResponseDTO>> =>
  axiosClient.put<string, AxiosResponse<FastBoard.API.ApiAdminProjectResponseDTO>, FastBoard.API.ApiAdminProjectRenameDTO>(
    `/api/v2/admin/project/${id}/rename`,
    { name },
  );

export const moveUserDraftProject = ({ projectId, flowId }: MoveProjectPayload) =>
  axiosClient.post<string, AxiosResponse<FastBoard.API.ApiAdminProjectTransferToFlowResponseDTO>>(
    `/api/v2/admin/projects-flows/flow/${flowId}/project/${projectId}/transfer`,
  );

export const loadUserSources = ({
  userId,
}: LoadUserSourcesPayload): Promise<AxiosResponse<FastBoard.API.ApiAdminSourceUserListResponseDTO>> =>
  axiosClient.get(`/api/v2/admin/sources/for-user/${userId}`);

export const loadNoUserSources = (
  params: LoadUserSourcesPayload,
): Promise<AxiosResponse<FastBoard.API.ApiAdminSourcesListResponseDTO>> => axiosClient.get(`/api/v2/admin/sources`, { params });

export const createUserSource = ({ sourcesId, userId, type }: CreateUserSourcesPayload) =>
  axiosClient.post<string, AxiosResponse<FastBoard.API.ApiAdminSourcesUserResponseDTO>, FastBoard.API.SourcesAddManyToUserDTO>(
    `/api/v2/admin/sources/user/${userId}/add`,
    {
      type,
      sourcesId,
    },
  );

export const updateUserSource = ({ userId, sourceId, type }: UpdateUserSourcePayload) =>
  axiosClient.put<
    string,
    AxiosResponse<FastBoard.API.ApiAdminSourceUserResponseDTO>,
    FastBoard.API.ApiAdminSourceUpdateTypeFromUserDTO
  >(`/api/v2/admin/source/${sourceId}/user/${userId}/type`, {
    type,
  });

export const deleteUserSource = ({
  userId,
  sourceId,
}: DeleteUserSourcePayload): Promise<AxiosResponse<FastBoard.API.ApiAdminSourceDeleteUserResponseDTO>> =>
  axiosClient.delete(`/api/v2/admin/source/${sourceId}/user/${userId}/delete`);

export const loadUserGroups = ({
  userId,
}: LoadUserGroupsPayload): Promise<AxiosResponse<FastBoard.API.ApiAdminGroupListResponseDTO>> =>
  axiosClient.get(`/api/v2/admin/groups/${userId}`);

export const loadNoUserGroups = ({
  userId,
}: LoadUserGroupsPayload): Promise<AxiosResponse<FastBoard.API.ApiAdminUserGroupListResponseDTO>> =>
  axiosClient.get(`/api/v2/admin/user-groups`, { params: { excludeUsers: true, userId } });

export const deleteUserGroupAccess = ({
  userId,
  groupId,
}: DeleteUserGroupsPayload): Promise<AxiosResponse<FastBoard.API.ApiAdminGroupsAccessRemoveResponseDTO>> =>
  axiosClient.delete(`/api/v1/groups/access/${groupId}/${userId}`);

export const createUserGroupAccess = ({ userId, groupsId }: CreateUserGroupAccessPayload) =>
  axiosClient.post<
    string,
    AxiosResponse<FastBoard.API.ApiAdminGroupsAddManyToUserResponseDTO>,
    FastBoard.API.ApiAdminGroupsAddManyToUserDTO
  >(`/api/v1/groups/access/many/user/${userId}`, { groupsId });

export const loadUserFlows = ({
  userId,
}: LoadUserFlowsPayload): Promise<AxiosResponse<FastBoard.API.ApiAdminFlowsUserWithTypeListResponseDTO>> =>
  axiosClient.get(`/api/v2/admin/flows/for-user/${userId}`);

export const loadNoUserFlows = (
  params: LoadUserFlowsPayload,
): Promise<AxiosResponse<FastBoard.API.ApiAdminFlowsListResponseDTO>> => axiosClient.get(`/api/v2/admin/flows`, { params });

export const createUserFlow = ({ flowsId, userId, type }: CreateUserFlowsPayload) =>
  axiosClient.post<string, AxiosResponse<FastBoard.API.ApiAdminManyFlowsUserResponseDTO>, FastBoard.API.ApiAdminFlowsAddUserDTO>(
    `/api/v2/admin/many/flows/user/${userId}/add`,
    {
      type,
      flowsId,
    },
  );

export const updateUserFlow = ({ userId, flowId, type }: UpdateUserFlowsPayload) =>
  axiosClient.put<
    string,
    AxiosResponse<FastBoard.API.ApiAdminFlowUserResponseDTO>,
    FastBoard.API.ApiAdminFlowUpdateTypeFromUserDTO
  >(`/api/v2/admin/flows/${flowId}/user/${userId}/type`, {
    type,
  });

export const deleteUserFlow = ({
  userId,
  flowId,
}: DeleteUserFlowPayload): Promise<AxiosResponse<FastBoard.API.ApiAdminFlowUserResponseDTO>> =>
  axiosClient.delete(`/api/v2/admin/flows/${flowId}/user/${userId}`);

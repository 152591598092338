import { BlockVisibilitySettingsStateInterface } from './types';
import {
  defaultModelTitle,
  fontSettingsTitle,
  gridSpacingTitle,
  isRealDataTitle,
  scaleOfPageTitle,
  showWorkSpaceTitle,
  viewModeTitle,
} from 'modules/workspace/components/panelSettingsApp/tabsContent/SettingsTab/constants';

export const initialBlockVisibilitySettingsState: BlockVisibilitySettingsStateInterface = {
  blockVisibility: {
    [viewModeTitle]: { isOpen: true },
    [defaultModelTitle]: { isOpen: true },
    [isRealDataTitle]: { isOpen: true },
    [fontSettingsTitle]: { isOpen: true },
    [showWorkSpaceTitle]: { isOpen: true },
    [gridSpacingTitle]: { isOpen: true },
    [scaleOfPageTitle]: { isOpen: true },
  },
  allMainContainersIsOpen: false,
};

import React, { forwardRef, MouseEventHandler } from 'react';
import { colorSize } from 'modules/workspace/components/panelSettingsApp/tabsContent/PalettesTab/constants';
import { ActiveColorInterface } from 'modules/workspace/components/panelSettingsApp/tabsContent/PalettesTab/PaletteColorSettings/types';
import { defaultActiveColor } from 'modules/workspace/components/panelSettingsApp/tabsContent/PalettesTab/PaletteColorSettings/constants';
import { SelectableColorTransparent } from '../../SelectableColorTransparent';

interface PaletteColorBlockProps {
  colorId: string;
  groupId: string;
  colorIndex: number;
  hex: string;
  value: ActiveColorInterface;
  onChange: React.Dispatch<React.SetStateAction<ActiveColorInterface>>;
  onColorSelectorClick: MouseEventHandler<HTMLDivElement>;
  name: string;
  isAllActiveColor?: boolean;
}

// eslint-disable-next-line react/display-name
export const PaletteColorBlock = forwardRef<HTMLDivElement, PaletteColorBlockProps>(
  ({ colorId, groupId, onColorSelectorClick, colorIndex, hex, value, onChange, name, isAllActiveColor }, ref) => {
    return (
      <div ref={ref}>
        <div onClick={onColorSelectorClick}>
          <SelectableColorTransparent
            disabledDeleteIcon
            name={name}
            colorId={colorId}
            groupId={groupId}
            colorIndex={colorIndex}
            hex={hex}
            colorSize={colorSize}
            value={value}
            onChange={onChange}
            defaultActiveColor={defaultActiveColor}
            isAllActiveColor={isAllActiveColor}
          />
        </div>
      </div>
    );
  },
);

import { FlexContainer } from 'styles/FlexContainer';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import TextField from 'modules/ui/TextField';
import React from 'react';
import { OnValueChange } from 'types/global';

interface NumberPropertiesBlockProps<T extends number | string = number> extends OnValueChange<T> {
  name: string;
  properties?: string | JSX.Element;
  unit?: string;
  width?: string;
  padding?: string;
  disabled?: boolean;
  debounced?: boolean;
  heightSize?: 'small' | 'normal';
  allowZero?: boolean;
  isFirstLevel?: boolean;
  needBorderBottom?: boolean;
  needBackground?: boolean;
  type?: 'number' | 'text';
}

export const NumberPropertiesBlock = <T extends number | string = number>({
  properties,
  name,
  unit,
  width = '54px',
  padding,
  value,
  onChange,
  disabled = false,
  debounced = false,
  heightSize,
  allowZero = true,
  isFirstLevel = false,
  needBackground = false,
  needBorderBottom = false,
  type = 'number',
}: NumberPropertiesBlockProps<T>) => {
  const onChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = event.target.value;

    if (!allowZero) {
      newValue = newValue.replace(/^0+/, '');

      if (newValue === '' || newValue === '0') return onChange((type === 'number' ? 1 : '') as T);
    }

    if (newValue === '') {
      return onChange((type === 'number' ? 0 : '') as T);
    }

    if (type === 'number') {
      return onChange(Number(newValue.replace(/\D/g, '')) as T);
    }
    onChange(newValue as T);
  };

  return (
    <FlexContainer alignItems="center" gap="5px">
      {properties &&
        (typeof properties === 'string' ? (
          <PrimaryTextSpan lineHeight="12px" color={`var(${isFirstLevel ? ColorVarsEnum.Level_1 : ColorVarsEnum.Level_3})`}>
            {properties}
          </PrimaryTextSpan>
        ) : (
          <>{properties}</>
        ))}
      <FlexContainer width={width}>
        <TextField
          onChange={onChangeText}
          value={type === 'number' ? Number(value) : value}
          type={type}
          name={name}
          disabled={disabled}
          needBackground={needBackground}
          needBorderBottom={needBorderBottom}
          width={width}
          heightSize={heightSize}
          useDebounce={debounced}
          padding={padding}
        />
      </FlexContainer>
      {unit && (
        <PrimaryTextSpan lineHeight="10px" color={`var(${ColorVarsEnum.Level_3})`}>
          {unit}
        </PrimaryTextSpan>
      )}
    </FlexContainer>
  );
};

import { PayloadAction } from '@reduxjs/toolkit';
import { defaultContainerSettings } from 'constants/store';
import {
  AddNewFilterPayload,
  DateFilterDataInterface,
  DefaultSelectedValuesInterface,
  EnabledFilterDataType,
  EnabledFilterInterface,
  FilterDataType,
  FilterInterface,
  FilterNameSettingsInterface,
  FilterStateInterface,
  FilterType,
  SingleFilterDataInterface,
  SingleFilterSettingsInterface,
  UpdateFilterPayload,
} from 'store/reducers/filters/types';
import { FilterElementModeEnum, IdInterface } from 'types/store';
import { OverflowValueEnum } from '../visualisations/types';
import { defaultStyleContainerSettings, initialPositionConfig } from '../visualisations/constants';
import { SortingValueEnum } from 'components/shared/SortingPanel/types';

export const initialFilterStoreState: FilterStateInterface = {
  filtersData: {
    filtersValues: {},
  },
  filters: {},
  serverStateOfFilters: null,
  filtersByPages: {},
  filtersByProject: {},
  enabledFilters: {},
  serverStateOfEnabledFilters: null,
  enabledFiltersByPages: {},
  filtersLoading: false,
  filtersValuesLoadingList: {},
  filtersErrorsList: {},
  enabledFiltersLoadingList: {},
  alreadyLoadedContent: {
    filters: new Set<string>(),
    enabledFilters: new Set<string>(),
  },
};

const defaultSingleFilterSettings: SingleFilterSettingsInterface = {
  paddings: { horizontal: 30, vertical: 10, isConnection: false },
};

const defaultSingleFilterData: SingleFilterDataInterface = { selectedData: [] };

const defaultEnabledDateFilterData: DateFilterDataInterface = {
  byType: 'byDay',
  startDate: null,
  endDate: null,
};

export const getInitialFilterFn: <Type extends FilterType, FilterData = object, SettingsData = object>(params: {
  type: Type;
  filterData: FilterData;
  settingsData: SettingsData;
  isAlwaysOpen?: boolean;
  sortingStatus?: SortingValueEnum;
}) => (params: AddNewFilterPayload) => FilterInterface<Type, FilterData, SettingsData> =
  ({ type, filterData, settingsData, isAlwaysOpen, sortingStatus }) =>
  ({ id, pageId, name }) => {
    return {
      ...filterData,
      ...settingsData,
      ...defaultContainerSettings,
      sortingStatus,
      isAlwaysOpen,
      id,
      pageId,
      type,
      positionConfig: {
        ...initialPositionConfig,
        width: 400,
        height: 60,
      },
      isRealData: false,
      isGlobal: false,
      isMultipleMode: null,
      isVisible: true,
      isBlock: false,
      filterInfluences: null,
      sqlData: {
        incisionRequest: '',
        filterAndGroupRequest: '',
      },
      limit: {
        value: 2000,
        isActive: false,
      },
      fictionalData: [],
      nameSettings: {
        name: 'Фильтр',
        fieldName: null,
        nameFromDatabase: false,
        filterElementMode: FilterElementModeEnum.MANUAL,
      },
      position: 'flex-start',
      overflowPosition: { label: 'Последовательно', value: OverflowValueEnum.SEQUENTIALLY },
      modelId: null,
      group: {
        isGrouped: false,
        groupId: null,
        positionConfig: initialPositionConfig,
      },
      name: name || '',
      hint: {
        isShow: false,
        text: 'Подсказка',
      },
      viewSettings: {
        styleContainerSettings: {
          ...defaultStyleContainerSettings,
          showBackground: false,
        },
      },
    };
  };

export const initialFnByFilterType = {
  single: getInitialFilterFn({
    type: 'single',
    filterData: defaultSingleFilterData,
    settingsData: defaultSingleFilterSettings,
    sortingStatus: SortingValueEnum.ASC,
  }),
  multiple: getInitialFilterFn({
    type: 'multiple',
    filterData: {},
    settingsData: {},
    isAlwaysOpen: false,
  }),
  date: getInitialFilterFn({
    type: 'date',
    filterData: {},
    settingsData: {},
  }),
};

const filterMapTypes: Record<FilterType, undefined> = { single: undefined, date: undefined, multiple: undefined };
const filterTypes: FilterType[] = Object.keys(filterMapTypes) as FilterType[];

export const referenceFilters = filterTypes.reduce(
  (references, type) => ({
    ...references,
    [type]: initialFnByFilterType[type]({ id: 'reference', pageId: 'reference', name: '' }),
  }),
  {} as Record<FilterType, FilterDataType>,
);

export const getInitialEnabledFilterFn: <
  FilterType,
  SelectedValues extends { selectedValues: unknown } = DefaultSelectedValuesInterface,
>(params: {
  type: FilterType;
  selectedValues: SelectedValues;
}) => (params: AddNewFilterPayload) => EnabledFilterInterface<FilterType, SelectedValues> =
  ({ type, selectedValues }) =>
  ({ id, pageId }) => {
    return {
      id,
      pageId,
      type,
      ...selectedValues,
      isGlobal: false,
      isRealData: false,
      nameSettings: {
        fieldName: null,
        name: 'reference',
        nameFromDatabase: false,
        filterElementMode: FilterElementModeEnum.MANUAL,
      },
      link: 'filter',
      linkId: 'reference',
      modelId: null,
      filterInfluences: null,
      incisionRequest: '',
    };
  };

export const initialEnabledFnByFilterType = {
  single: getInitialEnabledFilterFn({
    type: 'single',
    selectedValues: { selectedValues: [] },
  }),
  multiple: getInitialEnabledFilterFn({
    type: 'multiple',
    selectedValues: { selectedValues: [] },
  }),
  date: getInitialEnabledFilterFn({
    type: 'date',
    selectedValues: { selectedValues: defaultEnabledDateFilterData },
  }),
};

export const referenceEnabledFilter = filterTypes.reduce(
  (references, type) => ({
    ...references,
    [type]: initialEnabledFnByFilterType[type]({ id: 'reference', pageId: 'reference', name: '' }),
  }),
  {} as Record<FilterType, EnabledFilterDataType>,
);

export const getFilterFieldName = ({ nameFromDatabase, name, fieldName }: FilterNameSettingsInterface['nameSettings']) =>
  nameFromDatabase ? fieldName || '' : name;

export const removeFilterByIdFunc = (state: FilterStateInterface, { payload: { id } }: PayloadAction<IdInterface>) => {
  const pageId = state.filters[id]?.pageId;

  if (pageId) {
    delete state.filters[id];
    delete state.filtersByProject[id];

    const setOfIds = state.filtersByPages[pageId] || new Set<string>();
    setOfIds.delete(id);

    state.filtersByPages = { ...state.filtersByPages, [pageId]: setOfIds };
  }
};

export const updateFilterSettingsFunc = (
  state: FilterStateInterface,
  { payload: { id, data } }: PayloadAction<UpdateFilterPayload>,
) => {
  const filter = state.filters[id];

  if (filter) {
    state.filters[id] = { ...filter, ...data };
    state.filtersByProject[id] = { ...filter, ...data };
  }
};

import styled, { css } from 'styled-components';
import {
  settingsLayoutHeight,
  settingsLayoutMaxHeight,
  settingsLayoutWidth,
  ShadowSideEnum,
} from 'modules/settingsContainer/SettingsLayout/constants';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { MainContainer } from 'modules/settingsContainer/MainContainerSettings';
import { bottomLineMixin } from 'constants/styles';

export const settingsLayoutMixin = css`
  display: flex;
  flex-direction: column;
  width: ${settingsLayoutWidth};
  max-height: ${settingsLayoutMaxHeight};
  background-color: var(${ColorVarsEnum.Level_3_menu});
  box-shadow: 0 2px 70px rgba(0, 0, 0, 0.35);
  z-index: 1;
`;

export const SettingsLayoutWrapper = styled.div`
  position: absolute;
  ${settingsLayoutMixin};
`;

interface SettingsLayoutContainerWrapperProps {
  shadowSide: 'left' | 'right';
}

export const SettingsLayoutContainerWrapper = styled.div<SettingsLayoutContainerWrapperProps>`
  position: relative;
  ${settingsLayoutMixin};
  box-shadow: ${({ shadowSide }) =>
    shadowSide === ShadowSideEnum.LEFT ? '-5px 0 15px -5px rgba(19,17,30,0.15)' : '5px 0 15px -5px rgba(19,17,30,0.15)'};
  height: ${settingsLayoutHeight};

  ${({ shadowSide }) =>
    shadowSide === ShadowSideEnum.RIGHT &&
    css`
      border-left: 1px solid var(${ColorVarsEnum.Level_5});
    `}

  ${({ shadowSide }) =>
    shadowSide === ShadowSideEnum.LEFT &&
    css`
      border-right: 1px solid var(${ColorVarsEnum.Level_5});
    `}
`;

export const ContainerChildren = styled.div`
  ${MainContainer}:not(:last-child) {
    :after {
      ${bottomLineMixin}
    }
  }
`;

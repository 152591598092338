import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { NoopType } from 'types/global';
import { FieldSettingsArea } from 'modules/settingsContainer/FieldSettingsArea';
import { FlexContainer } from 'styles/FlexContainer';
import { Groups } from 'modules/settingsContainer/ColorPicker/styles';
import { AddColorButton } from 'modules/ui/buttons/AddColorButton';
import {
  colorSchemeItems,
  maxColorInGroupAi,
  colorGapAi,
  colorSizeAi,
  validationSchema,
  defaultNameGenerationColor,
} from 'modules/workspace/components/panelSettingsApp/tabsContent/PalettesTab/AIColorGenerationSettings/constants';
import { v4 as uuidv4 } from 'uuid';
import { getPaletteColorItem } from 'store/reducers/palettes/constants';
import { HexAndHlsColorEditor } from 'modules/ui/colors/HexAndHlsColorEditor';
import { HexAndHLSColorInterface } from 'types/store';
import { ElementContainerSettings, GroupContainerSettings, MainContainerSettings } from 'modules/settingsContainer';
import { debounce } from 'lodash';
import { Button, Select, TextField } from 'modules/ui';
import SubSection from 'shared/ui/subSection';
import { Controller, Resolver, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import {
  AIColorGenerationFormCallbackProps,
  AiGenerationColorPalettesType,
} from 'modules/workspace/components/panelSettingsApp/tabsContent/PalettesTab/AIColorGenerationSettings/types';
import { addGroupToCurrentPaletteAction, createGeneratePaletteAction } from 'store/reducers/palettes/actions';
import { useAppDispatch } from 'store';
import { unwrapResult } from '@reduxjs/toolkit';
import { PaletteColorItemInterface } from 'store/reducers/palettes/types';
import {
  colorSize,
  maxColorInGroup,
  colorGap,
} from 'modules/workspace/components/panelSettingsApp/tabsContent/PalettesTab/constants';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { PrimaryTextParagraph, PrimaryTextSpan } from 'styles/TextsElements';
import Snackbar from 'services/Snackbar';
import { yupResolver } from '@hookform/resolvers/yup';
import { ColorTransparent } from 'components/shared/ColorTransparent';
import { Form } from './styles';
import { TooltipIconButton } from 'modules/ui/TooltipIconButton';
import { DeleteIcon } from 'assets/icons/withContainer';
import { findNextIndex } from 'utils/utils';

type AIColorGenerationSettingsType = {
  onClose: NoopType;
  isOpen: boolean;
};

export const AIColorGenerationSettings = ({ onClose, isOpen }: AIColorGenerationSettingsType) => {
  const dispatch = useAppDispatch();

  const [activeColorIndex, setActiveColorIndex] = useState<number | null>(null);
  const [aiGenerationColorPalettes, setAiGenerationColorPalettes] = useState<AiGenerationColorPalettesType[]>([]);

  const paletteColorItem = useCallback((id: string) => getPaletteColorItem({ id }), []);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<AIColorGenerationFormCallbackProps>({
    mode: 'onSubmit',
    defaultValues: {
      colors: [paletteColorItem(uuidv4())],
      numberVariations: 8,
      colorScheme: 'monochrome',
    },
    resolver: yupResolver(validationSchema) as Resolver<AIColorGenerationFormCallbackProps>,
  });

  const colors = watch('colors');

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'colors',
  });

  const activeColorData = useMemo(() => {
    if (activeColorIndex !== null && fields[activeColorIndex]) {
      const { h, s, l, hex, name } = fields[activeColorIndex];
      return { h, s, l, hex, name };
    }
    return null;
  }, [activeColorIndex, fields]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onHexAndHlsColor = useCallback(
    debounce((color: HexAndHLSColorInterface) => {
      if (activeColorIndex !== null) {
        update(activeColorIndex, {
          ...fields[activeColorIndex],
          ...color,
        });
      }
    }, 300),
    [activeColorIndex, fields, update],
  );

  useEffect(() => {
    return () => {
      onHexAndHlsColor.cancel();
    };
  }, [onHexAndHlsColor]);

  const onAddColor = useCallback(() => {
    append(paletteColorItem(uuidv4()));

    setActiveColorIndex(colors.length);
  }, [append, colors.length, paletteColorItem]);

  const onDeleteColor = useCallback(
    (index: number) => {
      remove(index);
      if (activeColorIndex === index) {
        setActiveColorIndex(null);
      } else if (activeColorIndex !== null && activeColorIndex > index) {
        setActiveColorIndex(activeColorIndex - 1);
      }
    },
    [remove, activeColorIndex],
  );

  const onSubmit: SubmitHandler<AIColorGenerationFormCallbackProps> = useCallback(
    async (data) => {
      if (isSubmitting) {
        return null;
      }

      try {
        const hex = data.colors.map(({ hex }) => hex);

        const actionResult = await dispatch(
          createGeneratePaletteAction({
            color_count: data.numberVariations,
            color: hex,
            model: data.colorScheme,
          }),
        );

        const unwrappedResult = unwrapResult(actionResult);

        const nextIndex = findNextIndex(
          aiGenerationColorPalettes.map(({ name }) => name),
          defaultNameGenerationColor,
        );
        const nameGenerationColor = defaultNameGenerationColor + nextIndex;

        setAiGenerationColorPalettes((prev) => [...prev, { id: uuidv4(), colors: unwrappedResult, name: nameGenerationColor }]);
      } catch (error) {
        console.error(error);
        Snackbar.show('Ошибка при генерации палитры', 'error');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [aiGenerationColorPalettes, isSubmitting],
  );

  const onAddGroup = useCallback(
    (colors: PaletteColorItemInterface[]) => {
      dispatch(addGroupToCurrentPaletteAction({ group: { colors } }));
      Snackbar.show('Группа успешно добавлена в палитру', 'success');
    },
    [dispatch],
  );

  const onDeleteAiGenerationGroup = useCallback(
    (id: string) =>
      setAiGenerationColorPalettes((aiGenerationColorPalettes) =>
        aiGenerationColorPalettes.filter((aiGenerationColorPalette) => aiGenerationColorPalette.id !== id),
      ),
    [],
  );

  if (!isOpen) {
    return null;
  }

  return (
    <FieldSettingsArea onClose={onClose}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <MainContainerSettings>
          <SubSection>
            <GroupContainerSettings gap="18px">
              <ElementContainerSettings>
                <Groups maxColorInGroup={maxColorInGroupAi} colorGap={colorGapAi} colorSize={colorSizeAi}>
                  {fields.map((field, index) => (
                    <ColorTransparent
                      shape="circle"
                      active={activeColorIndex === index}
                      onClick={() => setActiveColorIndex(index)}
                      key={field.id}
                      color={field.hex}
                      colorSize={colorSizeAi}
                      disabledDeleteIcon={fields.length === 1}
                      onDelete={() => onDeleteColor(index)}
                    />
                  ))}
                  {fields.length < 8 && <AddColorButton onAdd={onAddColor} />}
                  {errors.colors && (
                    <PrimaryTextSpan lineHeight="12px" color={`var(${ColorVarsEnum.Alert})`} fontSize="12px">
                      {errors.colors.message}
                    </PrimaryTextSpan>
                  )}
                </Groups>
              </ElementContainerSettings>
              {activeColorIndex !== null && activeColorData && (
                <ElementContainerSettings>
                  <HexAndHlsColorEditor
                    disabledName
                    value={activeColorData}
                    onChange={onHexAndHlsColor}
                    disableHls={{ opacity: true }}
                  />
                </ElementContainerSettings>
              )}
              <ElementContainerSettings padding="0 8px">
                <FlexContainer justifyContent="space-between" width="100%" alignItems="flex-end">
                  <Controller
                    name="numberVariations"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        error={errors.numberVariations?.message}
                        widthContainer="120px"
                        label="Число вариаций"
                        width="100%"
                        {...field}
                      />
                    )}
                  />
                  <FlexContainer marginTop="5px">
                    <Controller
                      name="colorScheme"
                      control={control}
                      render={({ field }) => (
                        <Select
                          needBackground={false}
                          title="Цветовая схема"
                          options={colorSchemeItems}
                          width="160px"
                          {...field}
                          value={field.value}
                        />
                      )}
                    />
                  </FlexContainer>
                </FlexContainer>
              </ElementContainerSettings>
              <ElementContainerSettings marginTop="14px" marginLeft="8px" gap="8px">
                <Button width="auto" text="Сгенерировать" type="submit" disabled={isSubmitting} />
                <Button width="auto" text="Очистить" onClick={() => setAiGenerationColorPalettes([])} />
              </ElementContainerSettings>
            </GroupContainerSettings>
          </SubSection>
          {!!aiGenerationColorPalettes.length && (
            <SubSection>
              <GroupContainerSettings>
                <ElementContainerSettings flexDirection="column" gap="24px" maxHeight="300px" overflowY="auto">
                  {aiGenerationColorPalettes?.map(({ id, colors, name }) => (
                    <FlexContainer width="100%" flexDirection="column" key={id} alignItems="flex-start">
                      <PrimaryTextParagraph marginLeft="8px" fontSize="14px" color={`var(${ColorVarsEnum.Level_1})`}>
                        {name}
                      </PrimaryTextParagraph>
                      <Groups maxColorInGroup={maxColorInGroup} colorGap={colorGap} colorSize={colorSize}>
                        {colors?.map(({ id: colorId, hex }) => (
                          <ColorTransparent key={colorId} color={hex} colorSize={colorSize} disabledDeleteIcon />
                        ))}
                      </Groups>
                      <FlexContainer alignItems="center" marginLeft="8px" flexDirection="row" gap="8px">
                        <Button text="Добавить в палитру" onClick={() => onAddGroup(colors)} />
                        <TooltipIconButton
                          title="Удалить"
                          leftIcon={<DeleteIcon />}
                          onClick={() => onDeleteAiGenerationGroup(id)}
                        />
                      </FlexContainer>
                    </FlexContainer>
                  ))}
                </ElementContainerSettings>
              </GroupContainerSettings>
            </SubSection>
          )}
        </MainContainerSettings>
      </Form>
    </FieldSettingsArea>
  );
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { BoardPositionConfigInterface, IdInterface, PageIdInterface, ProjectIdWithType } from 'types/store';
import { loadGroupsVisualisationsByPageId } from './api';
import { TState } from 'store/index';
import { validateError } from 'store/reducers/visualisations/actions';
import {
  adjustChildGroupPositionsActionInterface,
  BoardElementActivateEnum,
  CreateNewGroupActionInterface,
  determineParentGroupIdActionInterface,
  GroupsVisualisationsActionsTypes,
  GroupWidgetSettingsInterface,
  LoadGroupsVisualisationsOutput,
  RemoveGroupVisualisationsInterface,
  UpdateGroupVisualisationsInterface,
  UpdateViewSettingsGroupVisualisationsInterface,
  UpdateWidgetGroupsInterface,
  VisualisationsGroupTypesEnum,
  WidgetGroupInterface,
} from './types';
import { getGroupsAlreadyLoaded, getWidgetGroupById, getWidgetsGroupsByPageId } from './getters';
import {
  addWidgetGroup,
  removeWidgetGroup,
  repeatAddWidgetGroup,
  repeatRemoveWidgetGroup,
  repeatUpdateWidgetGroup,
  repeatUpdateWidgetGroupPosition,
  updateGroupWidgetIds,
  updateOfIds,
  updateViewSettingsWidgetGroup,
  updateWidgetGroup,
  updateWidgetGroupPosition,
  updateWidgetGroups,
} from './index';
import {
  calculateGroupPositionConfig,
  calculatePositionConfigForWidgetInsideGroup,
  generateWidgetName,
  mergeByReference,
} from 'utils/utils';
import {
  addToLayerAction,
  getAllWidgetByIdsAction,
  removeBoardElementByIdAction,
  setActiveBoardElementAction,
} from 'store/reducers/board/actions';
import {
  DefaultVisualisationOptionsType,
  UpdatePositionConfigPayload,
  VisualisationIdsByPageInterface,
} from 'store/reducers/visualisations/types';
import { getInitialGroup, initialWidgetGroupForComparison } from './constants';
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';
import { v4 } from 'uuid';
import { UpdateWidgetGroupSettingsInterface } from 'modules/workspace/components/WorkAreaSpace/types';
import { updateTypedFilterActionById } from 'store/reducers/filters/actions';
import { getActivePageId } from 'store/reducers/projectPages/getters';
import { getActiveBoardElements } from 'store/reducers/board/getters';
import { initialPositionConfig } from 'store/reducers/visualisations/constants';
import { setActiveBoardElement } from 'store/reducers/board';
import { FilterDataType, FilterTypes, GroupTypeEnum } from 'store/reducers/filters/types';
import snackbar from 'services/Snackbar';
import { updatePositionForGroupConfig, updateViewSettingsForGroupData } from 'store/reducers/visualisations';
import { ReturnCommonWithIdInterface } from 'store/reducers/history/types';
import { SettingsSnapshotType } from '../projectSettings/settingsSnapshotService';

export const addWidgetGroupAction = createAsyncThunk(
  GroupsVisualisationsActionsTypes.ADD_WIDGET_GROUP,
  async ({ group }: { group: WidgetGroupInterface }, { dispatch, getState }) => {
    const groups = getWidgetsGroupsByPageId(group.pageId)(getState() as TState);
    const name = generateWidgetName(groups, VisualisationsGroupTypesEnum.GROUP);
    const newGroup = getInitialGroup({ widgetGroup: { ...group, name } });
    const { id, widgetIds, pageId } = newGroup;

    dispatch(addWidgetGroup(newGroup));
    dispatch(updateWidgetGroupsAction({ widgetIds, isGrouped: true, parentGroupId: id }));
    dispatch(setActiveBoardElementAction({ id }));
    pageId && dispatch(updateOfIdsAction({ widgetIds, pageId, id, addWidget: true }));
  },
);

export const addWidgetGroupByDataAction = createAsyncThunk(
  GroupsVisualisationsActionsTypes.ADD_WIDGET_GROUP_BY_DATA,
  async (data: GroupWidgetSettingsInterface, { dispatch, getState }) => {
    const { pageId, id, widgetIds } = data;
    const groups = getWidgetsGroupsByPageId(pageId)(getState() as TState);
    const name = generateWidgetName(groups, VisualisationsGroupTypesEnum.GROUP);

    dispatch(addWidgetGroup({ ...data, name }));
    dispatch(updateWidgetGroupsAction({ widgetIds, isGrouped: true, parentGroupId: id }));
    dispatch(setActiveBoardElementAction({ id }));
    pageId && dispatch(updateOfIdsAction({ widgetIds, pageId, id, addWidget: true }));
  },
);

export const repeatAddWidgetGroupAction = createAsyncThunk(
  GroupsVisualisationsActionsTypes.REPEAT_ADD_WIDGET_GROUP,
  async ({ group }: { group: GroupWidgetSettingsInterface }, { dispatch }) => {
    const { widgetIds, pageId, id } = group;

    dispatch(repeatAddWidgetGroup(group));
    dispatch(updateWidgetGroupsAction({ widgetIds, isGrouped: true, parentGroupId: id }));
    dispatch(setActiveBoardElementAction({ id: group.id }));
    pageId && dispatch(updateOfIdsAction({ widgetIds, pageId, id, addWidget: true }));
  },
);

export const handleAddWidgetGroupAction = createAsyncThunk(
  GroupsVisualisationsActionsTypes.HANDLE_ADD_WIDGET_GROUP,
  async (_, { dispatch, getState }) => {
    const state = getState() as TState;
    const activePageId = getActivePageId(state);
    const widgetGroups = getWidgetsGroupsByPageId(activePageId)(state);
    const activeWidgets = getActiveBoardElements(state);

    if (isNull(activeWidgets) || activeWidgets?.length <= 1) return;

    const selectedElements = await dispatch(getAllWidgetByIdsAction(activeWidgets)).unwrap();

    const groupId = v4();
    const groupPositionConfig = calculateGroupPositionConfig(selectedElements);

    const parentGroupId = await dispatch(determineParentGroupIdAction({ widgetGroups, selectedElements, groupId })).unwrap();

    if (isUndefined(parentGroupId)) return;

    await dispatch(adjustChildGroupPositionsAction({ widgetGroups, selectedElements, groupPositionConfig, parentGroupId }));

    await dispatch(
      createNewGroupAction({
        groupId,
        activePageId,
        activeWidgets,
        groupPositionConfig,
        parentGroupId,
        selectedElements,
      }),
    );

    await dispatch(addToLayerAction(groupId));
  },
);

export const removeGroupAction = createAsyncThunk<
  void,
  {
    activeGroup: GroupWidgetSettingsInterface;
    widgetGroups: GroupWidgetSettingsInterface[];
    isHistoryAction?: boolean;
    customParentGroupId?: string | null;
  }
>(
  GroupsVisualisationsActionsTypes.REMOVE_GROUP,
  async ({ activeGroup, widgetGroups, isHistoryAction, customParentGroupId }, { dispatch }) => {
    const { id, pageId, widgetIds, parentGroupId: parentGroupIdActiveGroup, positionConfig } = activeGroup;
    const parentGroupId = isUndefined(customParentGroupId) ? parentGroupIdActiveGroup : customParentGroupId;
    const selectedElements = widgetIds && (await dispatch(getAllWidgetByIdsAction(widgetIds)).unwrap());

    if (parentGroupId) {
      const parentGroup = widgetGroups.find((group) => group.id === parentGroupId);

      if (parentGroup) {
        const newParentWidgetIds = [...(parentGroup.widgetIds || []), ...(widgetIds || [])].filter((widgetId) => id !== widgetId);

        dispatch(
          repeatUpdateWidgetGroupAction({
            id: parentGroupId,
            settings: {
              widgetIds: newParentWidgetIds,
            },
          }),
        );
      }
    }

    widgetIds?.forEach((widgetId) => {
      const group = widgetGroups.find((group) => group.id === widgetId);

      if (group) {
        const { x, y } = group.positionConfig;
        const { x: parentGroupX, y: parentGroupY } = positionConfig;

        dispatch(
          repeatUpdateWidgetGroupAction({
            id: group.id,
            settings: {
              parentGroupId,
              positionConfig: { ...group.positionConfig, x: x + parentGroupX, y: y + parentGroupY },
            },
          }),
        );
      }
    });

    isHistoryAction
      ? dispatch(
          repeatRemoveWidgetGroupAction({
            id,
            widgetIds,
            pageId,
            parentGroupId,
          }),
        )
      : dispatch(removeWidgetGroupAction({ id, widgetIds, pageId, parentGroupId }));

    selectedElements &&
      dispatch(
        updateWidgetsGroupSettingsAction({
          isGrouped: !isNull(parentGroupId),
          groupId: parentGroupId,
          groupPositionConfig: positionConfig || initialPositionConfig,
          selectedElements,
          ...(!isNull(parentGroupId) && { customCalculatedPositionConfig: false }),
        }),
      );

    dispatch(setActiveBoardElement({ id: widgetIds, type: BoardElementActivateEnum.MULTIPLE }));
    return;
  },
);

export const removeWidgetGroupAction = createAsyncThunk(
  GroupsVisualisationsActionsTypes.REMOVE_WIDGET_GROUP,
  async (params: RemoveGroupVisualisationsInterface, { dispatch }) => {
    const { widgetIds, pageId, id, parentGroupId = null } = params;
    const newWidgetIds = widgetIds?.filter((widgetId) => id !== widgetId);

    newWidgetIds &&
      dispatch(
        updateWidgetGroupsAction({
          widgetIds: newWidgetIds,
          isGrouped: !isNull(parentGroupId),
          parentGroupId,
        }),
      );
    dispatch(removeWidgetGroup(params));
    pageId && dispatch(updateOfIdsAction({ ...params, addWidget: false }));
  },
);

export const repeatRemoveWidgetGroupAction = createAsyncThunk(
  GroupsVisualisationsActionsTypes.REPEAT_REMOVE_WIDGET_GROUP,
  async (params: RemoveGroupVisualisationsInterface, { dispatch }) => {
    const { pageId } = params;

    dispatch(repeatRemoveWidgetGroup(params));
    pageId && dispatch(updateOfIdsAction({ ...params, addWidget: false }));
  },
);

export const handleRemoveFullWidgetGroupAction = createAsyncThunk(
  GroupsVisualisationsActionsTypes.HANDLE_REMOVE_FULL_WIDGET_GROUP,
  (_, { dispatch, getState }) => {
    const state = getState() as TState;
    const activeWidgets = getActiveBoardElements(state);

    if (isNull(activeWidgets)) return;

    dispatch(removeGroupByWidgetIdsAction({ widgetIds: activeWidgets, isFirstInput: true }));
  },
);

export const removeGroupByWidgetIdsAction = createAsyncThunk<string[], { widgetIds: string[]; isFirstInput?: boolean }>(
  GroupsVisualisationsActionsTypes.REMOVE_GROUP_BY_WIDGET_IDS,
  async ({ widgetIds, isFirstInput }, { dispatch, getState }) => {
    const state = getState() as TState;
    const activePageId = getActivePageId(state);
    const widgetGroups = getWidgetsGroupsByPageId(activePageId)(state);
    const newActiveElementsId: string[] = [];

    for (const id of widgetIds) {
      const activeGroup = widgetGroups.find((group) => group.id === id);

      if (!activeGroup) {
        newActiveElementsId.push(id);
        continue;
      }

      await dispatch(removeGroupAction({ activeGroup, widgetGroups, customParentGroupId: null }));

      if (activeGroup?.widgetIds) {
        const activeWidgetIds = await dispatch(removeGroupByWidgetIdsAction({ widgetIds: activeGroup.widgetIds })).unwrap();

        newActiveElementsId.push(...activeWidgetIds);
      }
    }

    isFirstInput && dispatch(setActiveBoardElement({ id: newActiveElementsId, type: BoardElementActivateEnum.MULTIPLE }));
    return newActiveElementsId;
  },
);

export const updateWidgetsRemoveGroupAction = createAsyncThunk<unknown, IdInterface>(
  GroupsVisualisationsActionsTypes.UPDATE_WIDGETS_REMOVE_GROUP,
  async ({ id }, { dispatch, getState }) => {
    const state = getState() as TState;
    const activePageId = getActivePageId(state);
    const widgetGroups = getWidgetsGroupsByPageId(activePageId)(state);

    const activeGroup = widgetGroups.find((group) => group.id === id);

    if (activeGroup) {
      dispatch(removeGroupAction({ activeGroup, widgetGroups }));
    }
  },
);

export const removeAllSelectedGroupsAction = createAsyncThunk(
  GroupsVisualisationsActionsTypes.REMOVE_ALL_SELECTED_GROUPS,
  async (_, { dispatch, getState }) => {
    const state = getState() as TState;
    const activePageId = getActivePageId(state);
    const widgetGroups = getWidgetsGroupsByPageId(activePageId)(state);
    const activeWidgets = getActiveBoardElements(state);

    activeWidgets?.forEach((activeElementId) => {
      const activeGroup = widgetGroups.find((group) => group.id === activeElementId);

      if (activeGroup) {
        const { id } = activeGroup;
        dispatch(updateWidgetsRemoveGroupAction({ id }));
      }
    });
  },
);

export const deleteFullWidgetGroupActionById = createAsyncThunk(
  GroupsVisualisationsActionsTypes.DELETE_FULL_WIDGET_GROUP_BY_ID,
  async ({ id }: IdInterface, { dispatch, getState }) => {
    const widgetGroup = getWidgetGroupById(id)(getState() as TState);

    if (isUndefined(widgetGroup)) return;

    const { widgetIds, pageId } = widgetGroup;
    const allParams = { widgetIds, pageId, id };

    widgetIds?.forEach((id) => {
      dispatch(removeBoardElementByIdAction(id));
    });

    dispatch(removeWidgetGroup(allParams));
    pageId && dispatch(updateOfIdsAction({ ...allParams, addWidget: false }));
  },
);

export const updateWidgetGroupsAction = createAsyncThunk(
  GroupsVisualisationsActionsTypes.UPDATE_WIDGET_GROUPS,
  async (params: UpdateWidgetGroupsInterface, { dispatch }) => {
    dispatch(updateWidgetGroups(params));
  },
);

export const updateOfIdsAction = createAsyncThunk(
  GroupsVisualisationsActionsTypes.UPDATE_OF_IDS,
  async (params: RemoveGroupVisualisationsInterface, { dispatch }) => {
    dispatch(updateOfIds(params));
  },
);

export const updateWidgetGroupAction = createAsyncThunk(
  GroupsVisualisationsActionsTypes.UPDATE_WIDGET_GROUP,
  async (params: UpdateGroupVisualisationsInterface, { dispatch }) => {
    dispatch(updateWidgetGroup(params));
  },
);

export const repeatUpdateWidgetGroupAction = createAsyncThunk(
  GroupsVisualisationsActionsTypes.REPEAT_UPDATE_WIDGET_GROUP,
  async (params: UpdateGroupVisualisationsInterface, { dispatch }) => {
    dispatch(repeatUpdateWidgetGroup(params));
  },
);

export const updateViewSettingsWidgetGroupAction = createAsyncThunk(
  GroupsVisualisationsActionsTypes.UPDATE_VIEW_SETTINGS_WIDGET_GROUP,
  async (params: UpdateViewSettingsGroupVisualisationsInterface, { dispatch }) => {
    dispatch(updateViewSettingsWidgetGroup(params));
  },
);

export const updateWidgetGroupPositionAction = createAsyncThunk(
  GroupsVisualisationsActionsTypes.UPDATE_WIDGET_GROUP_POSITION,
  async (params: UpdatePositionConfigPayload, { dispatch }) => {
    dispatch(updateWidgetGroupPosition(params));
  },
);

export const loadGroupsVisualisationsAction = createAsyncThunk(
  GroupsVisualisationsActionsTypes.LOAD_GROUPS_VISUALISATIONS,
  async (params: ProjectIdWithType<PageIdInterface>, { dispatch, getState, signal }) => {
    const visualisationsAlreadyLoaded = getGroupsAlreadyLoaded(params.pageId)(getState() as TState);

    if (!visualisationsAlreadyLoaded) {
      const request = dispatch(loadGroupsVisualisationsByPageIdAction(params));

      signal.addEventListener('abort', () => {
        request.abort();
      });
    }
  },
);

export const loadGroupsVisualisationsFromSnapshotAction = createAsyncThunk<
  LoadGroupsVisualisationsOutput,
  SettingsSnapshotType['groupsVisualisations']
>(GroupsVisualisationsActionsTypes.LOAD_GROUPS_VISUALISATIONS_FROM_SNAPSHOT, (groups) => {
  const originGroupsVisualisations = groups.reduce<GroupWidgetSettingsInterface[]>(
    (result, data) => [...result, ...(data.groupVisualisations as GroupWidgetSettingsInterface[])],
    [],
  );

  /* Merging reference visualization data with visualization data stored on the BE. When new data (new features) is added to the visualization, this data will be synchronized with the BE data. */
  const groupsVisualisations = mergeByReference({
    originData: originGroupsVisualisations,
    getReferenceByType: () => getInitialGroup(initialWidgetGroupForComparison),
    typeKey: 'type',
  });

  const groupsVisualisationsByPages = groups.reduce<VisualisationIdsByPageInterface>(
    (result, { pageId, groupVisualisations }) => ({
      ...result,
      [pageId]: new Set<string>(groupVisualisations.map(({ id }) => id)),
    }),
    {},
  );

  return {
    groupsByPages: groupsVisualisationsByPages,
    widgetGroups: groupsVisualisations,
  };
});

export const loadGroupsVisualisationsByPageIdAction = createAsyncThunk<
  LoadGroupsVisualisationsOutput,
  ProjectIdWithType<PageIdInterface>
>(
  GroupsVisualisationsActionsTypes.LOAD_GROUPS_VISUALISATIONS_BY_PAGE_ID,
  async (params, { rejectWithValue, signal, dispatch }) => {
    try {
      const response = await loadGroupsVisualisationsByPageId(params, { signal });

      const originGroupsVisualisations = response.data.groupVisualisations as GroupWidgetSettingsInterface[];

      const groupsVisualisations = mergeByReference({
        originData: originGroupsVisualisations,
        getReferenceByType: () => getInitialGroup(initialWidgetGroupForComparison),
        typeKey: 'type',
      });

      return {
        groupsByPages: { [params.pageId]: new Set(originGroupsVisualisations.map(({ id }) => id)) },
        widgetGroups: groupsVisualisations,
      };
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return rejectWithValue({ visualisationsByPages: {}, visualisations: {} });
    }
  },
);

export const determineParentGroupIdAction = createAsyncThunk<string | null | undefined, determineParentGroupIdActionInterface>(
  GroupsVisualisationsActionsTypes.DETERMINE_PARENT_GROUP_ID,
  async ({ widgetGroups, selectedElements, groupId }, { dispatch }) => {
    const idsParent = new Set<string | null>();

    selectedElements.forEach((element) => {
      const isGroup = (element as GroupWidgetSettingsInterface)?.type === GroupTypeEnum.GROUP;

      if (isGroup) {
        const parentGroupId = (element as GroupWidgetSettingsInterface)?.parentGroupId;
        return idsParent.add(parentGroupId);
      }

      const { id } = element;
      const groupId = (element as DefaultVisualisationOptionsType)?.viewSettings?.group.groupId;
      const group = widgetGroups.find((group) => group.id === id || group.id === groupId);

      isNull(groupId) && idsParent.add(groupId);
      group && groupId && idsParent.add(groupId);
    });

    if (idsParent.size > 1) {
      snackbar.show('Ошибка группировки. Объекты находятся в разных группах', 'error');
      return;
    }
    const parentGroupId = idsParent.size === 1 ? idsParent.values().next().value : null;

    if (parentGroupId) {
      const parentGroup = widgetGroups.find((group) => group.id === parentGroupId);

      if (parentGroup) {
        const idsSelectedElements = selectedElements.map(({ id }) => id);
        const updatedWidgetIds = parentGroup.widgetIds?.filter((widgetId) => !idsSelectedElements.includes(widgetId));

        dispatch(
          repeatUpdateWidgetGroupAction({
            id: parentGroupId,
            settings: {
              widgetIds: [...(updatedWidgetIds || []), groupId],
            },
          }),
        );
      }
    }

    return parentGroupId;
  },
);

export const adjustChildGroupPositionsAction = createAsyncThunk<void, adjustChildGroupPositionsActionInterface>(
  GroupsVisualisationsActionsTypes.ADJUST_CHILD_GROUP_POSITIONS,
  async ({ selectedElements, widgetGroups, groupPositionConfig, parentGroupId }, { dispatch }) => {
    for (const element of selectedElements) {
      const { id } = element;
      const groupId = (element as DefaultVisualisationOptionsType)?.viewSettings?.group.groupId;

      const group = widgetGroups.find((group) => group.id === id || group.id === groupId);

      if (group && parentGroupId !== group.id) {
        const { x, y } = group.positionConfig;
        const { x: parentGroupX, y: parentGroupY } = groupPositionConfig;

        dispatch(
          repeatUpdateWidgetGroupAction({
            id: group.id,
            settings: {
              positionConfig: { ...group.positionConfig, x: x - parentGroupX, y: y - parentGroupY },
            },
          }),
        );
      }
    }
  },
);

export const createNewGroupAction = createAsyncThunk<void, CreateNewGroupActionInterface>(
  GroupsVisualisationsActionsTypes.CREATE_NEW_GROUP,
  async (params, { dispatch }) => {
    const { groupId, activePageId, activeWidgets, groupPositionConfig, parentGroupId, selectedElements } = params;

    dispatch(
      addWidgetGroupAction({
        group: {
          id: groupId,
          pageId: activePageId,
          widgetIds: activeWidgets,
          positionConfig: groupPositionConfig,
          parentGroupId,
          isGrouped: !isNull(parentGroupId),
        },
      }),
    );

    dispatch(
      updateWidgetsGroupSettingsAction({
        isGrouped: true,
        groupId,
        groupPositionConfig,
        selectedElements,
      }),
    );
  },
);

export const returnUpdateWidgetGroupPositionAction = createAsyncThunk<
  void,
  ReturnCommonWithIdInterface<BoardPositionConfigInterface>
>(GroupsVisualisationsActionsTypes.RETURN_UPDATE_WIDGET_GROUP_POSITION, async ({ id, data }, { dispatch }) => {
  dispatch(repeatUpdateWidgetGroupPosition({ id, positionConfig: data }));
});

export const updateWidgetsGroupSettingsAction = createAsyncThunk(
  GroupsVisualisationsActionsTypes.UPDATE_WIDGETS_GROUP_SETTINGS,
  async (params: UpdateWidgetGroupSettingsInterface, { dispatch }) => {
    const { selectedElements, customCalculatedPositionConfig, groupPositionConfig, isGrouped, groupId } = params;

    selectedElements.forEach((selectedElement) => {
      const newIsGrouped = isUndefined(customCalculatedPositionConfig) ? isGrouped : customCalculatedPositionConfig;
      const updatedPositionConfig = calculatePositionConfigForWidgetInsideGroup(
        selectedElement.positionConfig,
        groupPositionConfig,
        newIsGrouped,
      );

      const groupSettings = {
        freeLocation: true,
        group: {
          isGrouped,
          groupId,
          positionConfig: updatedPositionConfig,
        },
      };

      if (FilterTypes.includes((selectedElement as FilterDataType).type)) {
        return dispatch(
          updateTypedFilterActionById({
            id: selectedElement.id,
            data: { ...groupSettings, positionConfig: updatedPositionConfig },
          }),
        );
      }

      if ((selectedElement as GroupWidgetSettingsInterface).type === GroupTypeEnum.GROUP) {
        dispatch(returnUpdateWidgetGroupPositionAction({ id: selectedElement.id, data: updatedPositionConfig }));
        return dispatch(
          updateWidgetGroupsAction({
            widgetIds: [selectedElement.id],
            isGrouped: isGrouped,
            parentGroupId: groupId,
          }),
        );
      }

      dispatch(updateViewSettingsForGroupData({ id: selectedElement.id, viewSettings: groupSettings }));
      dispatch(updatePositionForGroupConfig({ id: selectedElement.id, positionConfig: updatedPositionConfig }));
    });
  },
);

export const moveElementInsideGroup = createAsyncThunk(
  GroupsVisualisationsActionsTypes.MOVE_ELEMENT_INSIDE_GROUP,
  async ({ direction }: { direction: 'left' | 'right' }, { getState, dispatch }) => {
    const state = getState() as TState;
    const activePageId = getActivePageId(state);
    const widgetGroups = getWidgetsGroupsByPageId(activePageId)(state);
    const activeWidgets = getActiveBoardElements(state);

    if (!activeWidgets || activeWidgets.length !== 1) return;

    const activeWidgetId = activeWidgets[0];

    const targetGroup = widgetGroups.find((group) => group.widgetIds?.includes(activeWidgetId));
    if (!targetGroup?.widgetIds) return;

    const widgetIndex = targetGroup.widgetIds.indexOf(activeWidgetId);

    if (widgetIndex === -1) return;

    const newIndex =
      direction === 'left' ? Math.max(0, widgetIndex - 1) : Math.min(targetGroup.widgetIds.length - 1, widgetIndex + 1);

    if (newIndex === widgetIndex) return;

    const updatedWidgetIds = [...targetGroup.widgetIds];
    updatedWidgetIds.splice(widgetIndex, 1);
    updatedWidgetIds.splice(newIndex, 0, activeWidgetId);

    dispatch(
      updateGroupWidgetIds({
        widgetIds: updatedWidgetIds,
        groupId: targetGroup.id,
      }),
    );
  },
);

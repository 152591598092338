import { BoardStateInterface, getPositionConfigForPasteWidgetProps, UpdateLayerPayload } from 'store/reducers/board/types';
import { PayloadAction } from '@reduxjs/toolkit';
import { getPositionConfigForPasteInCenterBlock } from 'utils/utils';
import isUndefined from 'lodash/isUndefined';

export const initialBoardStoreState: BoardStateInterface = {
  buffer: null,
  activeBoardElements: null,
  activeBoardElementInViewMode: null,
  isMouseInside: null,
  pagesLayers: {},
  serverStateOfPagesLayers: null,
  layersLoading: false,
  editInfluenceFiltersMode: false,
  alreadyLoadedContent: {
    layers: new Set<string>(),
  },
};

export const deleteFromLayerFunc = (
  state: BoardStateInterface,
  { payload: { id, pageId } }: PayloadAction<UpdateLayerPayload>,
) => {
  const pageLayers = state.pagesLayers[pageId];

  if (pageLayers) {
    state.pagesLayers[pageId] = pageLayers.filter((layerId) => layerId !== id);
  }
};

export const getActiveBoardElementFunc = ({ activeElements }: { activeElements: string[] | null }) =>
  activeElements ? activeElements[0] : null;

export const getPositionConfigForPasteWidget = ({
  isGroupedValue,
  positionConfig,
  newParentGroupId,
  groupPositionConfig,
  shiftActive,
}: getPositionConfigForPasteWidgetProps) => {
  return (
    (isGroupedValue &&
      isUndefined(newParentGroupId) && {
        ...positionConfig,
        x: 0,
        y: 0,
      }) ||
    (!isUndefined(shiftActive) &&
      !shiftActive &&
      getPositionConfigForPasteInCenterBlock({
        initialPosition: positionConfig,
        groupPositionConfig,
      }))
  );
};

import { BodyWrapper, SettingsTabsWrapper, TabItem, TabsWrapper } from 'modules/ui/tabs/SettingsTabs/styles';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import { ErrorWrapper } from 'modules/visualisations/components/ErrorWrapper';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Loader } from 'shared/ui/Loader';
import { FlexContainer } from 'styles/FlexContainer';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { Tab, TabTypeEnum } from '../types';
import { StyledTooltip } from 'modules/ui/StyledTooltip';
import { MenuListComponent } from 'components/shared/ui/menu/MenuIconList';
import { Option } from 'components/shared/ui/menu/MenuIconList/types';

interface SettingsTabsProps {
  tabs: Tab[];
  optionsPointsMenu?: Option[];
}

/* TODO: It will be necessary to merge or replace SettingsTabs and NavigationsTabs */

export const SettingsTabs = ({ tabs, optionsPointsMenu }: SettingsTabsProps) => {
  const defaultTab = useMemo(() => tabs[0], [tabs]);
  const [activeTabId, setActiveTabId] = useState(defaultTab.id);

  const activeTab = useMemo(() => tabs.find(({ id }) => id === activeTabId) || defaultTab, [activeTabId, defaultTab, tabs]);

  const { Component, isLoading } = activeTab;

  const ComponentRender = useCallback(() => {
    return (
      <ErrorWrapper>
        <Component />
      </ErrorWrapper>
    );
  }, [Component]);

  useEffect(() => {
    return () => {
      setActiveTabId(activeTabId || defaultTab.id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs]);

  return (
    <SettingsTabsWrapper>
      {tabs.length > 1 && (
        <TabsWrapper>
          {tabs.map((tab) => {
            const { id, content, type, prompt } = tab;
            const Content = content;

            return (
              <StyledTooltip key={id} disableHoverListener={false} title={prompt} placement="top">
                <TabItem isActive={activeTab.id === id} onClick={() => setActiveTabId(id)} key={id}>
                  {type === TabTypeEnum.TEXT && <PrimaryTextSpan title={content}>{content}</PrimaryTextSpan>}
                  {type === TabTypeEnum.ICON && (
                    <IconWrapper containerHeight="18px" containerWidth="20px" iconWidth="20px" iconHeight="20px" Icon={content} />
                  )}
                  {type === TabTypeEnum.COMPONENT && <Content />}
                </TabItem>
              </StyledTooltip>
            );
          })}
        </TabsWrapper>
      )}
      {optionsPointsMenu && (
        <FlexContainer position="absolute" right="0" top="0">
          <MenuListComponent disabledOnClickClose options={optionsPointsMenu} />
        </FlexContainer>
      )}
      <BodyWrapper>
        <ComponentRender />
        {isLoading && (
          <FlexContainer zIndex="2" position="absolute" width="100%" height="100%" alignItems="center" left="0" top="0">
            <Loader size="small" title="Загрузка настроек" />
          </FlexContainer>
        )}
      </BodyWrapper>
    </SettingsTabsWrapper>
  );
};

import React, { useMemo } from 'react';
import { ACTIVE_BOARD_AREA_LABEL } from 'modules/workspace/constans';
import { SettingsLayoutContainerWrapper } from 'modules/settingsContainer/SettingsLayout/styles';
import { Tab } from 'modules/ui/tabs/types';
import { PaletteIcon, ThemesIcon } from 'assets/pagesSettings';
import { SettingsTab } from 'modules/workspace/components/panelSettingsApp/tabsContent/SettingsTab';
import { PalettesTab } from 'modules/workspace/components/panelSettingsApp/tabsContent/PalettesTab';
import { ThemesTab } from 'modules/workspace/components/panelSettingsApp/tabsContent/ThemesTab';
import { useSelector } from 'react-redux';
import { getProjectSettingsLoading, getThemeSettingsLoadingLoading } from 'store/reducers/projectSettings/getters';
import { getPalettesLoading } from 'store/reducers/palettes/getters';
import { SettingsIcon } from 'assets/icons/withContainer';
import { ShadowSideEnum } from 'modules/settingsContainer/SettingsLayout/constants';
import { SettingsTabsWrapper } from 'modules/ui/tabs/SettingsTabsWrapper';

export const PanelModal = () => {
  const projectSettingsLoading = useSelector(getProjectSettingsLoading);
  const themeSettingsLoading = useSelector(getThemeSettingsLoadingLoading);
  const palettesLoading = useSelector(getPalettesLoading);

  const settingsTabs: Tab[] = useMemo(
    () => [
      {
        id: 'modes',
        Component: SettingsTab,
        type: 'icon',
        prompt: 'Основные настройки',
        content: SettingsIcon,
        isLoading: projectSettingsLoading,
      },
      {
        id: 'palette',
        Component: PalettesTab,
        type: 'icon',
        prompt: 'Палитры цветов',
        content: PaletteIcon,
        isLoading: palettesLoading,
      },
      {
        id: 'themes',
        Component: ThemesTab,
        type: 'icon',
        prompt: 'Темы оформления',
        content: ThemesIcon,
        isLoading: themeSettingsLoading,
      },
    ],
    [projectSettingsLoading, palettesLoading, themeSettingsLoading],
  );

  return (
    <SettingsLayoutContainerWrapper id={ACTIVE_BOARD_AREA_LABEL} shadowSide={ShadowSideEnum.LEFT}>
      <SettingsTabsWrapper tabs={settingsTabs} />
    </SettingsLayoutContainerWrapper>
  );
};

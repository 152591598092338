import { OnValueChange } from 'types/global';
import { FlexContainer } from 'styles/FlexContainer';
import React from 'react';
import TextField from 'modules/ui/TextField';
import { ColorTransparent } from 'components/shared/ColorTransparent';
import { normalizeHexColor } from 'utils/utils';

interface HexColorEditorProps extends OnValueChange<string> {
  colorSize?: number;
}

export const HexColorEditor = ({ value, onChange, colorSize = 25 }: HexColorEditorProps) => {
  const handleOnChange = (rawValue: string) => onChange(normalizeHexColor(rawValue));

  return (
    <FlexContainer alignItems="center" gap="10px">
      <ColorTransparent disabledDeleteIcon colorSize={colorSize} color={value} />

      <TextField
        widthContainer="130px"
        needBackground={false}
        needBorderBottom={false}
        width="auto"
        name="hex_color"
        value={value}
        onChange={(e) => handleOnChange(e.target.value)}
        formattingValue={normalizeHexColor}
      />
    </FlexContainer>
  );
};

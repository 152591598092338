import { pxUnit } from 'constants/global';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { Switcher } from 'modules/ui';
import React, { forwardRef, ReactElement, ReactNode, useId } from 'react';

import styled from 'styled-components';
import { FlexContainer, FlexContainerProps } from 'styles/FlexContainer';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { NoopValueType } from 'types/global';
import { ColorPicker } from './ColorPicker';
import { ColorValuesByThemeType, PaletteValuesByThemeType } from './ColorPicker/types';
import { NumberPropertiesBlock } from './common/NumberPropertiesBlock';
import { StyledTooltip } from 'modules/ui/StyledTooltip';
import { InfoIcon } from 'assets/icons/withContainer';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';

/* TODO: Refactoring this component */

interface IProps extends GroupContainerWrapperProps, FlexContainerProps {
  titleText?: string;
  children?: JSX.Element[] | JSX.Element | ReactNode | ReactElement<any, any> | any;
  switcherState?: boolean;
  switcherChange?: (e?: any) => void;
  color?: PaletteValuesByThemeType | ColorValuesByThemeType | null;
  countUnit?: number;
  colorChange?: NoopValueType<PaletteValuesByThemeType | ColorValuesByThemeType | null>;
  thicknessBorderChange?: NoopValueType<number>;
  unitName?: string;
  disabled?: boolean;
  titleTooltip?: string;
  infoTooltipText?: string;
}

// eslint-disable-next-line react/display-name
export const GroupContainerSettings = forwardRef<HTMLDivElement, IProps>(
  (
    {
      titleText = null,
      children = null,
      switcherState = false,
      switcherChange = null,
      showBottomLine,
      color,
      countUnit,
      colorChange,
      thicknessBorderChange,
      unitName,
      disabled = false,
      titleTooltip,
      infoTooltipText,
      ...props
    },
    ref,
  ) => {
    const uniqueId = useId();

    return (
      <GroupContainerWrapper ref={ref} flexDirection="column" gap="4px" padding=" 0 " showBottomLine={showBottomLine} {...props}>
        {titleText && (
          <StyledTooltip title={titleTooltip}>
            <FlexContainer margin="0" height="16px" alignItems="center" justifyContent="space-between" width="100%">
              <FlexContainer alignItems="center" width="100%">
                <PrimaryTextSpan
                  fontSize="12px"
                  lineHeight="12px"
                  color={`var(${ColorVarsEnum.Level_1})`}
                  marginRight={colorChange && thicknessBorderChange && 'auto'}
                >
                  {titleText}
                </PrimaryTextSpan>
                {infoTooltipText && (
                  <FlexContainer marginLeft="auto">
                    <StyledTooltip title={infoTooltipText}>
                      <IconWrapper
                        iconWidth="20px"
                        iconHeight="20px"
                        containerHeight="25px"
                        containerWidth="25px"
                        Icon={InfoIcon}
                      />
                    </StyledTooltip>
                  </FlexContainer>
                )}
              </FlexContainer>
              {colorChange && <ColorPicker value={color} onChange={(color) => colorChange(color)} />}
              {thicknessBorderChange && (
                <FlexContainer>
                  <NumberPropertiesBlock
                    name="countUnit"
                    unit={unitName || pxUnit}
                    value={countUnit || 0}
                    onChange={(countUnit) => thicknessBorderChange(countUnit)}
                    heightSize="small"
                  />
                </FlexContainer>
              )}
              <FlexContainer gap="16px" alignItems="center">
                {switcherChange && (
                  <Switcher id={uniqueId} value={switcherState} onClick={!disabled ? switcherChange : undefined} />
                )}
              </FlexContainer>
            </FlexContainer>
          </StyledTooltip>
        )}
        {children && (
          <FlexContainer padding={titleText ? '0 0 0 8px' : '0'} gap="4px" alignItems="center" flexDirection="column">
            {React.Children.map(children, (el) => el)}
          </FlexContainer>
        )}
      </GroupContainerWrapper>
    );
  },
);

interface GroupContainerWrapperProps {
  showBottomLine?: boolean;
}

const GroupContainerWrapper = styled(FlexContainer)<GroupContainerWrapperProps>`
  position: relative;
`;

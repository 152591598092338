import { createSelector } from 'reselect';
import { getState } from 'store/utils';

export const getBlockVisibilitySettingsStore = createSelector(getState, (state) => state.blockVisibilitySettings);

export const getBlockVisibilityStore = createSelector(getBlockVisibilitySettingsStore, (state) => state.blockVisibility);

export const getAllMainContainersIsOpen = createSelector(
  getBlockVisibilitySettingsStore,
  (state) => state.allMainContainersIsOpen,
);

import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom';
import { goToFlowDraftURL } from './constants';
import React, { Suspense } from 'react';
import PrivateRoute from 'shared/privateRoute';
import AdminRoute from 'shared/adminRoute';
import { ProjectRoure } from 'shared/projectRoure';
import { routesConfig } from './routes';
import { Loader } from 'shared/ui/Loader';
import { RouteConfig } from './types';

export const RouteWrapper: React.FC<{ route: RouteConfig }> = ({ route }) => {
  const element = (
    <Suspense fallback={<Loader />}>
      <route.component />
    </Suspense>
  );

  if (route.isProjectRoute) {
    return (
      <PrivateRoute>
        <ProjectRoure>{element}</ProjectRoure>
      </PrivateRoute>
    );
  }

  if (route.access === 'admin') {
    return (
      <PrivateRoute>
        <AdminRoute>{element}</AdminRoute>
      </PrivateRoute>
    );
  } else if (route.access === 'private') {
    return <PrivateRoute>{element}</PrivateRoute>;
  }

  return element;
};

const routesArray = Object.values(routesConfig);

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      {routesArray.map((route) => (
        <Route key={route.path} path={route.path} element={<RouteWrapper route={route} />} />
      ))}
      <Route path="*" element={<Navigate to={goToFlowDraftURL()} replace />} />
    </>,
  ),
);

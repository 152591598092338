export const settingsLayoutWidth = '350px';
export const settingsLayoutWidthSecondLevel = '328px';
export const settingsLayoutMaxHeight = 'calc(100vh - 52px)';
export const settingsLayoutHeight = 'calc(100vh - 52px)';

export const settingsLayoutTopPosition = '-250px';

export enum ShadowSideEnum {
  LEFT = 'left',
  RIGHT = 'right',
}

import { DefaultVisualisationOptionsType } from 'store/reducers/visualisations/types';
import { FilterDataType } from 'store/reducers/filters/types';
import { MoveToType } from 'utils/utils';
import { BoardPositionConfigInterface, IdInterface, PageIdInterface } from 'types/store';
import { MapRecordType } from 'types/global';
import { GroupWidgetSettingsInterface } from 'store/reducers/groupsVisualisations/types';

export type BufferType = DefaultVisualisationOptionsType | FilterDataType | GroupWidgetSettingsInterface;

/* Store SLice Type */

export interface BoardStateInterface {
  buffer: BufferType[] | null;
  activeBoardElements: string[] | null;
  activeBoardElementInViewMode: string | null;
  isMouseInside: boolean | null;
  pagesLayers: LayersMap;
  serverStateOfPagesLayers: LayersMap | null;
  layersLoading: boolean;
  editInfluenceFiltersMode: boolean;
  alreadyLoadedContent: {
    layers: Set<string>;
  };
}

export type LayersMap = MapRecordType<string[]>;

/* Action Types */

export enum BoardActionsTypes {
  SET_ACTIVE_BOARD_ELEMENT = 'SET_ACTIVE_BOARD_ELEMENT',
  COPY_TO_BUFFER = 'COPY_TO_BUFFER',
  CUT_TO_BUFFER = 'CUT_TO_BUFFER',
  PASTE_FROM_BUFFER = 'PASTE_FROM_BUFFER',
  REMOVE_BOARD_ELEMENT_BY_ID = 'REMOVE_BOARD_ELEMENT_BY_ID',
  REMOVE_BOARD_ELEMENT = 'REMOVE_BOARD_ELEMENT',
  ADD_TO_LAYER = 'ADD_TO_LAYER',
  DELETE_FROM_LAYER = 'DELETE_FROM_LAYER',
  DELETE_LAYER_BY_PAGE_ID = 'DELETE_LAYER_BY_PAGE_ID',
  ADD_LAYER_BY_PAGE_ID = 'ADD_LAYER_BY_PAGE_ID',
  LOAD_LAYERS = 'LOAD_LAYERS',
  LOAD_LAYERS_BY_PAGE_ID = 'LOAD_LAYERS_BY_PAGE_ID',
  LOAD_LAYERS_FROM_SNAPSHOT = 'LOAD_LAYERS_FROM_SNAPSHOT',
  UPDATE_BY_ID_LAYERS = 'UPDATE_BY_ID_LAYERS',
  UPDATE_LAYERS = 'UPDATE_LAYERS',
  CLEAR_BOARD_STORE = 'CLEAR_BOARD_STORE',
  MOVE_VISUALIZATION = 'MOVE_VISUALIZATION',
  ADD_NEW_WIDGETS_FROM_BUFFER = 'ADD_NEW_WIDGETS_FROM_BUFFER',
  GET_ALL_WIDGET_BY_IDS = 'GET_ALL_WIDGET_BY_IDS',
  GET_DISABLE_DRAGGING_ACTIVE_WIDGETS = 'GET_DISABLE_DRAGGING_ACTIVE_WIDGETS',
}

/* Payload */
export interface MoveLayersPayload extends IdInterface {
  moveTo: MoveToType;
}

export interface UpdateLayerPayload extends PageIdInterface, IdInterface {}

export interface UpdateLayersPayload extends PageIdInterface {
  layers: string[];
}

export enum TabType {
  TEXT = 'text',
  ICON = 'icon',
}

export type MoveType = 'top' | 'down' | 'left' | 'right';

export interface UpdateMoveXYInterface {
  positionConfig: BoardPositionConfigInterface;
  type: MoveType;
  gridSpacing: number;
  sizePage: {
    height: number;
    width: number;
  };
}

export interface getPositionConfigForPasteWidgetProps {
  isGroupedValue: boolean;
  positionConfig: BoardPositionConfigInterface;
  newParentGroupId?: string;
  groupPositionConfig?: BoardPositionConfigInterface;
  shiftActive?: boolean;
}

import { VisualSettingsStateInterface } from 'store/reducers/visualSettings/types';
import { PanelModal } from 'modules/workspace/components/panelSettingsApp';

const defaultOpenSettings = {
  isOpen: false,
  width: 350,
};

export const initialVisualSettingsStoreState: VisualSettingsStateInterface = {
  SettingComponent: PanelModal,
  openSettingsComponents: {
    PagesSettings: defaultOpenSettings,
    SettingsTab: defaultOpenSettings,
    SQLFullCode: defaultOpenSettings,
    VisualisationsList: defaultOpenSettings,
    ObjectVisualisationList: defaultOpenSettings,
  },
  visibilityAllSettings: true,
};

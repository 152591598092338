import { useDispatch, useSelector } from 'react-redux';
import React, { Children, forwardRef, ReactElement, ReactNode, useCallback, useId, useState, useRef, useEffect } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { Switcher } from '../ui';
import styled from 'styled-components';
import { IconType } from 'assets/icons/withContainer';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import { ArrowIcon } from 'modules/ui/icons/ArrowIcon/styles';
import { getAllMainContainersIsOpen, getBlockVisibilityStore } from 'store/reducers/blockVisibilitySettings/getters';
import { blockToggleSetting } from 'store/reducers/blockVisibilitySettings';
import { bottomLineStyleMixin } from 'constants/styles';
import { StyledTooltip } from 'modules/ui/StyledTooltip';

interface IProps {
  titleText?: string;
  children?: JSX.Element[] | JSX.Element | ReactNode | ReactElement<any, any> | any;
  switcherState?: boolean;
  switcherChange?: (e?: any) => void;
  customPadding?: string;
  ButtonIcon?: IconType;
  onClickButtonIcon?: (e?: any) => void;
  onDropDownClick?: (isOpen: boolean) => void;
  disabled?: boolean;
  buttonIconTitle?: string;
  titleTooltip?: string;
}

// eslint-disable-next-line react/display-name
export const MainContainerSettings = forwardRef<HTMLDivElement, IProps>(
  (
    {
      titleText = '',
      children = null,
      switcherState = false,
      switcherChange = null,
      customPadding,
      ButtonIcon,
      onClickButtonIcon,
      disabled = false,
      buttonIconTitle,
      titleTooltip,
    },
    ref,
  ) => {
    const dispatch = useDispatch();
    const blockVisibility = useSelector(getBlockVisibilityStore);
    const isOpenMainContainers = useSelector(getAllMainContainersIsOpen);

    const uniqueId = useId();

    const contentRef = useRef<HTMLDivElement>(null);

    const [padding, setPadding] = useState('12px 12px 0 12px');

    const isOpenTitleText = titleText === '';

    const open = blockVisibility[titleText] ? blockVisibility[titleText].isOpen : isOpenMainContainers || isOpenTitleText;

    useEffect(() => {
      if (contentRef.current && contentRef.current.firstChild) {
        const offsetLeft = (contentRef.current.firstChild as HTMLElement).offsetLeft;
        setPadding(offsetLeft === 0 ? '0px 12px 0 12px' : '12px');
      }
    }, [open, children]);

    const toggleShowSetting = useCallback(
      () => {
        dispatch(blockToggleSetting(titleText));
        onClickButtonIcon && onClickButtonIcon();
      }, // eslint-disable-next-line react-hooks/exhaustive-deps
      [titleText, onClickButtonIcon],
    );

    return (
      <MainContainer ref={ref}>
        <FlexContainer width="100%" flexDirection="column" padding="0px">
          {titleText && (
            <StyledTooltip title={titleTooltip}>
              <ClickZoneAreas
                isOpen={open}
                onClick={() => {
                  toggleShowSetting();
                  onClickButtonIcon && onClickButtonIcon();
                }}
              >
                <FlexContainer
                  alignItems="center"
                  justifyContent="space-between"
                  margin={children && open ? `0 ${customPadding ? '4px' : '0'} 0px` : '0'}
                  padding="12px 0"
                  height="16px"
                >
                  <PrimaryTextSpan fontSize="14px" lineHeight="14px" color={`var(${ColorVarsEnum.Level_1})`}>
                    {titleText}
                  </PrimaryTextSpan>
                  <FlexContainer gap="8px" alignItems="center">
                    {switcherChange && (
                      <Switcher id={uniqueId} value={switcherState} onClick={!disabled ? switcherChange : undefined} />
                    )}
                    {ButtonIcon && (
                      <FlexContainer zIndex="2">
                        <StyledTooltip title={buttonIconTitle}>
                          <IconWrapper
                            hoverColorVar={ColorVarsEnum.Level_1}
                            colorVar={ColorVarsEnum.Level_3}
                            containerWidth="20px"
                            containerHeight="20px"
                            iconHeight="16px"
                            iconWidth="16px"
                            Icon={ButtonIcon}
                          />
                        </StyledTooltip>
                      </FlexContainer>
                    )}
                    {children && <ArrowIcon isActive={open} />}
                  </FlexContainer>
                </FlexContainer>
                <BackgroundOnHover className="backgroundOnHover" />
              </ClickZoneAreas>
            </StyledTooltip>
          )}
          {open && children && (
            <ContentContainer ref={contentRef} padding={padding}>
              {Children.map(children, (el) => el)}
            </ContentContainer>
          )}
        </FlexContainer>
      </MainContainer>
    );
  },
);

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

interface IClickZoneAreas {
  isOpen: boolean;
}

export const ClickZoneAreas = styled.div<IClickZoneAreas>`
  position: relative;
  z-index: 1;
  cursor: pointer;
  overflow: hidden;
  padding: 8px 12px;

  :before {
    overflow: hidden;
    content: '';
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    bottom: ${({ isOpen }) => (isOpen ? '0' : '0px')};
    ${({ isOpen }) => !isOpen && bottomLineStyleMixin};
  }
`;

const BackgroundOnHover = styled.div`
  position: absolute;
  background: ${`var(${ColorVarsEnum.Level_1})`};
  opacity: 0.05;
  height: 40px;
  width: 100%;
  top: 0;
  left: 0;
  transition: 0.2s;
`;

const ContentContainer = styled(FlexContainer)<{ padding: string }>`
  flex-direction: column;
  z-index: 2;
  padding: ${({ padding }) => padding};
  overflow: hidden;
  & > *:not(:last-child) {
    position: relative;
  }
`;

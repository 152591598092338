import React, { FC, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { ClickAwayListener, FormControl, MenuItem, Select as MUISelect, Tooltip } from '@mui/material';
import { DownIcon, IconType } from 'assets/icons/withContainer';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { FlexContainer } from 'styles/FlexContainer';
import { SELECTOR_MENU } from 'modules/workspace/constans';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { useClickOutside } from 'utils/hooks/useOutsideClicks';

export const useOpenSelector = () => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    if (open) {
      setOpen(false);
    }
  };
  const onClickAway = (e: MouseEvent | TouchEvent) => {
    if (e.target !== document.body) {
      handleClose();
    }
  };
  const toggleOpen = () => setOpen((value) => !value);

  return { open, handleClose, onClickAway, toggleOpen };
};

export interface SelectItemInterface<T extends string = string> {
  name: string;
  value: T;
  tooltip?: string;
  Icon?: IconType;
  disabled?: boolean;
  isDeleted?: boolean;
}

export interface IProps {
  name: string;
  disabled?: boolean;
  onChange: (value: any) => void;
  heightSize?: 'small' | 'normal';
  needBackground?: boolean;
  needBorder?: boolean;
  visibleElements?: number;
  width?: string;
  multiple?: boolean;
  error?: boolean;
  title?: string;
  placeholder?: string;
  options: SelectItemInterface[] | any;
  value?: SelectItemInterface | any;
  disabledTooltip?: boolean;
}

const Select: FC<IProps> = ({
  name,
  disabled = false,
  heightSize = 'normal',
  needBackground = true,
  needBorder = true,
  visibleElements = 10,
  width = '190px',
  onChange,
  options,
  value,
  multiple = false,
  error = false,
  title = '',
  placeholder = '',
  disabledTooltip,
  ...props
}) => {
  const [openTitle, setOpenTitle] = useState<null | string>(null);
  const { open, handleClose, toggleOpen, onClickAway } = useOpenSelector();
  const addItemRef = useClickOutside(handleClose);

  const handleShowTitle = (id: string) => setOpenTitle(id);
  const handleHideTitle = () => setOpenTitle(null);

  // Проверяем, существует ли value в options
  const valueExistsInOptions = options.some((option: SelectItemInterface) => option.value === value);

  // Если значение не существует, добавляем его в options с пометкой "(удалено)"
  const modifiedOptions = useMemo(() => {
    if (value && !valueExistsInOptions) {
      const deletedOption: SelectItemInterface = {
        name: `${(value as SelectItemInterface).name || value} (удален)`,
        value: ((value as SelectItemInterface).value || value) as string,
        isDeleted: true,
      };
      return [...options, deletedOption];
    }
    return options;
  }, [options, value, valueExistsInOptions]);

  const handleShowName = useCallback(
    (value: string) => {
      const option = modifiedOptions.find((p: SelectItemInterface) => p.value === value);

      if (option?.Icon) {
        const { Icon } = option;
        return (
          <IconBlock>
            <Icon />
          </IconBlock>
        );
      }

      if (option) {
        return (
          <FlexContainer alignItems="center" height="100%">
            <PrimaryTextSpan
              fontSize="14px"
              color={`var(${ColorVarsEnum.Level_1})`}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              textAlign="left"
            >
              {option.name}
            </PrimaryTextSpan>
          </FlexContainer>
        );
      }

      return null;
    },
    [modifiedOptions],
  );

  return (
    <Container $width={width} ref={addItemRef}>
      {title && <PrimaryTextSpan color={`var(${ColorVarsEnum.Level_2})`}>{title}</PrimaryTextSpan>}
      <ClickAwayListener onClickAway={onClickAway}>
        <StyledSelect
          error={error}
          multiple={multiple}
          name={name}
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: `var(${ColorVarsEnum.Level_1})`,
            },
          }}
          value={((value as SelectItemInterface)?.value || value || '') as string}
          $width={width}
          disabled={disabled}
          $heightSize={heightSize}
          $needBackground={needBackground}
          $needBorder={needBorder}
          IconComponent={DownIcon}
          $visibleElements={visibleElements}
          displayEmpty
          $placeholder={placeholder}
          renderValue={(selectedValue: any) => handleShowName(selectedValue)}
          inputProps={{ 'aria-label': 'Without label' }}
          MenuProps={{ className: SELECTOR_MENU }}
          open={open}
          onOpen={toggleOpen}
          onClose={handleClose}
          {...props}
        >
          <FlexContainer flexDirection="column" width="100%" maxHeight={`${30 * visibleElements}px`}>
            {modifiedOptions &&
              modifiedOptions.map((item: SelectItemInterface) => {
                const { Icon } = item;
                const tooltip = item?.tooltip;
                const tooltipValue = tooltip || item.name;

                return (
                  <MenuItem
                    key={item.value}
                    value={item.value}
                    onMouseEnter={() => handleShowTitle(item.value)}
                    onMouseLeave={handleHideTitle}
                    onClick={() => {
                      onChange(item.value);
                      handleClose();
                    }}
                    style={item.isDeleted ? { color: `var(${ColorVarsEnum.Alert})` } : {}}
                    disabled={item.disabled}
                  >
                    {!disabledTooltip ? (
                      <Tooltip title={tooltipValue} id={tooltipValue} open={openTitle === item.value} placement="left">
                        <div>
                          {item.name}
                          {Icon && <Icon />}
                        </div>
                      </Tooltip>
                    ) : (
                      <div>
                        {item.name}
                        {Icon && <Icon />}
                      </div>
                    )}
                  </MenuItem>
                );
              })}
          </FlexContainer>
        </StyledSelect>
      </ClickAwayListener>
    </Container>
  );
};

export default Select;

export const Container = styled(FormControl)<Pick<IStyle, '$width'>>`
  width: ${(props) => props.$width};

  h5 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 100%;
    color: ${`var(${ColorVarsEnum.Level_2})`};
    margin: 0 0 3px;
  }
`;

export interface IStyle {
  $needBackground: boolean;
  $needBorder: boolean;
  $heightSize: string;
  $width: string;
  value: string;
  $placeholder: string;
  $visibleElements: number;
  error: boolean;
}

interface IconBlockProps {
  colorSvg?: string;
  size?: number;
}

export const IconBlock = styled.div<IconBlockProps>`
  display: flex;
  align-items: center;

  svg,
  path,
  circle,
  rect {
    fill: ${({ colorSvg }) => (colorSvg ? colorSvg : `var(${ColorVarsEnum.Level_3})`)};
    stroke: ${({ colorSvg }) => (colorSvg ? colorSvg : `var(${ColorVarsEnum.Level_3})`)};
  }

  svg {
    ${({ size }) => size && `width: ${size}px`};
    ${({ size }) => size && `height: ${size}px`};
  }
`;

const StyledSelect = styled(MUISelect)<IStyle>`
  background: ${(props) =>
    props.$needBackground ? `var(${ColorVarsEnum.Level_5_application})` : `var(${ColorVarsEnum.Level_3_menu})`};
  height: ${(props) => (props.$heightSize === 'normal' ? '30px' : '24px')};
  cursor: pointer !important;
  border-radius: 3px !important;
  border-bottom: ${(props) => (props.error ? `1px solid  var(${ColorVarsEnum.Alert})` : undefined)};
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;

  .MuiSelect-select {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: ${(props) => (props.$heightSize === 'normal' ? '200%' : 'unset')};
    -webkit-text-fill-color: ${`var(${ColorVarsEnum.Level_1})`};
    height: ${(props) => (props.$heightSize === 'normal' ? '28px !important' : '22px !important')};
    padding: 0 8px;
    top: 2px;
    border-style: solid !important;
    border-color: ${(props) =>
      props.$needBorder
        ? `var(${ColorVarsEnum.Level_4}) !important`
        : props.$needBackground
        ? `var(${ColorVarsEnum.Level_4}) !important`
        : props.value
        ? `var(${ColorVarsEnum.Level_4}) !important`
        : `var(${ColorVarsEnum.Level_3_menu}) !important`};
    border-width: 1px !important;
    border: ${(props) => !props.$needBorder && 'none !important'};
  }

  .MuiPaper-root {
    position: inherit !important;
    background: ${`var(${ColorVarsEnum.Level_3_menu})`};
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    border: 1px solid ${`var(${ColorVarsEnum.Level_4}) !important`};
    border-top-width: 0;
    width: ${(props) => props.$width};
    z-index: 9999 !important;

    ul {
      cursor: pointer;
      padding: 0;
      max-height: ${(props) =>
        props.$heightSize === 'normal' ? `calc(30px * ${props.$visibleElements})` : `calc(24px * ${props.$visibleElements})`};

      li {
        color: ${`var(${ColorVarsEnum.Level_1})`};
        transition: all 200ms;
        padding: 8px;
        height: ${(props) => (props.$heightSize === 'normal' ? '30px' : '24px')};
        background: ${`var(${ColorVarsEnum.Alert})`};
        text-overflow: ellipsis;
        white-space: nowrap;

        div,
        span {
          z-index: 2;
        }
      }

      li:hover {
        background-color: ${`var(${ColorVarsEnum.Level_3_menu})`} !important;

        :before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: ${`var(${ColorVarsEnum.Level_5})`};
          z-index: 1;
        }
      }

      .Mui-selected {
        color: ${`var(${ColorVarsEnum.Accent})`};
      }
    }
  }

  fieldset {
    box-sizing: border-box;
    border-radius: 3px;
    border-color: ${`var(${ColorVarsEnum.Level_4})`} !important;
    border-style: solid;
    border-width: ${(props) => (props.$needBackground || props.open ? '1px !important' : '0 !important')};
    transition: all 200ms;
    border: ${(props) => !props.$needBorder && 'none !important'};
  }

  svg {
    height: 16px;
    width: 16px;

    path {
      stroke: ${`var(${ColorVarsEnum.Level_3}) !important`};
    }
  }

  :hover {
    fieldset {
      border: unset !important;
    }

    background: ${`var(${ColorVarsEnum.Level_5_application})`};

    .MuiSelect-select {
      border-color: ${`var(${ColorVarsEnum.Level_4}) !important`};
    }
  }
`;
